/* eslint-disable arrow-body-style */
const ID_TOKEN_KEY = 'accessToken';
const ID_USER_KEY = 'currentUser';
// TODO use utilities session

export default {
  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },

  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },
  destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  },
  getUser() {
    return JSON.parse(window.localStorage.getItem(ID_USER_KEY));
  },
  saveUser(user) {
    window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user));
  },
  destroyUser() {
    window.localStorage.removeItem(ID_USER_KEY);
  },
};
