/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/drivers', { params });
  },
  get(id) {
    return ApiService.get('/driver', id);
  },

  newDriverSeries() {
    return ApiService.post('/driver-new-series');
  },
  getDrivers() {
    return ApiService.get('/drivers', { page: 0, size: 1000 });
  },
  create(data) {
    return ApiService.post('/driver', data);
  },
  update(id, data) {
    return ApiService.post(`/driver/${id}`, data);
  },
  uploadDocument(id, data) {
    return ApiService.post(`/driver/${id}/document`, data);
  },
  destroy(id) {
    return ApiService.delete(`/driver/${id}`);
  },
};
