
// export const API_URL = 'http://local-api.romfour.cloud/api';
// export const ROMFOUR_URL = 'http://local-api.romfour.cloud/api';
// export const APP_API_KEY = 'XXXXX';

// export const API_URL = 'https://stage-api.romfour.cloud/api';
// export const ROMFOUR_URL = 'http://stage-safir.romfour.cloud/';
// export const APP_API_KEY = 'F615g686p9zEADtJLtWq7EkmQL5PiX0z';

export const API_URL = 'https://api.romfour.cloud/api';
export const ROMFOUR_URL = 'https://romfour.com';
export const APP_API_KEY = 'F615g686p9zEADtJLtWq7EkmQL5PiX0z';

export default {API_URL , ROMFOUR_URL, APP_API_KEY};
