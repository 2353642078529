/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/vehiclesCourseType', { params });
  },
  get(vehicleId) {
    return ApiService.get('/vehicleCourseType', vehicleId);
  },
  //Check if the relation vehicle/hub already exists
  getRelations(params) {
    return ApiService.query('/vehicleCourseType', { params });
  },
  create(id, data) {
    return ApiService.post(`/vehicleCourseType/${id}`, data);
  },
  updateTransportType(id, data) {
    return ApiService.post(`/vehicleCourseType/${id}/transportType`, data);
  },
  delete(id, courseTypeId, data) {
    return ApiService.delete(`/vehicleCourseType/${id}/${courseTypeId}`, data);
  },
};
