/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  queryLeaveRequests(employeeId, params) {
    return ApiService.query('/employee-leave-requests/' + employeeId, { params });
  },
  createLeaveRequests(data) {
    return ApiService.post('/employee-leave-requests', data);
  },
  editLeaveRequests(leaveRequestId, data) {
    return ApiService.put(`/employee-leave-request/${leaveRequestId}`, data);
  },
  deleteLeaveRequests(leaveRequestId) {
    return ApiService.delete(`/employee-leave-request/${leaveRequestId}`);
  },
  query(params) {
    return ApiService.query('/employees', { params });
  },
  get(employeeId, params) {
    return ApiService.query('/employee/' + employeeId, params);
  },
};
