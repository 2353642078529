/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import VehiclesCourseTypeService from '@/services/vehiclesCourseType.service';

import {
  FETCH_VEHICLE,
  FETCH_VEHICLESCOURSETYPE,
  FETCH_VEHICLECOURSETYPE,
  VEHICLE_COURSE_TYPE_DELETE,
  UPDATE_SEARCH_VEHICLESCOURSETYPE,
  VEHICLE_COURSE_TYPE_EDIT_SUBMIT,
  VEHICLE_COURSE_TYPE_CREATE_SUBMIT,
  VEHICLE_COURSE_TYPE_UPDATE_TRANSPORTYPE,
  GET_COURSE_TYPE_RELATION
} from '../actions.type';

import {
  FETCH_VEHICLESCOURSETYPE_END,
  UPDATE_FILTER_VEHICLESCOURSETYPE_M,
} from '../mutations.type';
import {isNullOrUndefined} from "@tinymce/tinymce-vue/lib/cjs/main/ts/Utils";

export default {
  state: {
    filters: {
      vehicleType: null,
      vehicleClass: null,
      vehicleStatus: null,
      licensePlate: null,
      availableFor: null,
    },
    availableVehicleClasses: [],
    availableVehicleTypes: [],
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    sort: [],

  },
  getters: {
    vehiclesCourseTypeItems(state) {
      return state.data;
    },
  },
  mutations: {
    [FETCH_VEHICLESCOURSETYPE_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
  },
  actions: {
    [FETCH_VEHICLESCOURSETYPE](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };
        if (filters && filters.vehicleStatus && filters.vehicleStatus != '') {
          query.vehicleStatus = ['=', filters.vehicleStatus];
        }
        if (filters && filters.vehicleType && filters.vehicleType != '') {
          query.type = ['=', filters.vehicleType];
        }
        if (filters && filters.vehicleClass && filters.vehicleClass != '') {
          query.class = ['=', filters.vehicleClass];
        }
        if (filters && filters.hubId && filters.hubId != '') {
          query.hubId = ['h=', filters.hubId];
        }
        if (filters && filters.hubType && filters.hubType != '' && filters.hubType !== 'null') {
          query.hubType = ['hasType', filters.hubType];
        }
        if (filters && filters.licensePlate && filters.licensePlate != '') {
          query.licenseNumber = ['like', filters.licensePlate];
        }
        if (filters && filters.vehicleAvailabilityStatus && filters.vehicleAvailabilityStatus != '') {
          query.vehicleAvailabilityStatus = ['=', filters.vehicleAvailabilityStatus];
        }
        if (filters && !isNullOrUndefined(filters.driverEmployeeId)) {
          query.driverEmployeeId = ['=', filters.driverEmployeeId];
        }

        // do the actual API call
        VehiclesCourseTypeService.query(query)
          .then((response) => {
            context.commit(FETCH_VEHICLESCOURSETYPE_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_VEHICLECOURSETYPE](context, slug) {
      return new Promise((resolve, reject) => {
        VehiclesCourseTypeService.get(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [VEHICLE_COURSE_TYPE_EDIT_SUBMIT](context, params) {
      return new Promise((resolve, reject) => {
        VehiclesCourseTypeService.update(params.vehicleId, params.formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [VEHICLE_COURSE_TYPE_DELETE](context, params) {
      return new Promise((resolve, reject) => {
        VehiclesCourseTypeService.delete(params.vehicleId, params.courseTypeId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //Check if the current hub/vehicle relation exists
    [GET_COURSE_TYPE_RELATION](context, params) {
      var vehicleId = params.vehicleId;
      var hubId = params.hubId;
      let query = {vehicleId, hubId};
      return new Promise((resolve, reject) => {
        VehiclesCourseTypeService.getRelations(query)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [VEHICLE_COURSE_TYPE_CREATE_SUBMIT](context, params) {
      return new Promise((resolve, reject) => {
        VehiclesCourseTypeService.create(params.vehicleId, params.item, params.transportType)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [VEHICLE_COURSE_TYPE_UPDATE_TRANSPORTYPE](context, params) { //Updates the transport type for all the hubs register on the current vehicle
      return new Promise((resolve, reject) => {
        VehiclesCourseTypeService.updateTransportType(params.vehicleId, params.item)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [UPDATE_SEARCH_VEHICLESCOURSETYPE](context, params) {
      context.commit(UPDATE_FILTER_VEHICLESCOURSETYPE_M, params);
    },
  },
};
