<template>
  <b-navbar toggleable="sm" class="d-block d-sm-none bg-purple" type="dark">
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <div v-show="!$route.path.includes('/vehicles-folders')">
        <b-navbar-nav>
          <b-nav-item @click="gotoRoute('drivers')">Administrare Soferi</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item @click="gotoRoute('vehiclesList')">Autovehicule</b-nav-item>
        </b-navbar-nav>
      </div>
      <div v-show="$route.path.includes('/vehicles-folders')">
        <b-navbar-nav>
          <b-nav-item @click="gotoRoute('hubs')">Puncte Transfer / Hub-uri</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item @click="gotoRoute('vehiclesCourseType')">Autovehicule</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item @click="gotoRoute('vehicleStatus')">Situatie Autovehicule</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item @click="gotoRoute('routeDefinitions')">Tipuri Rute</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item @click="gotoRoute('cruiseList')">Lista Curse</b-nav-item>
        </b-navbar-nav>
         <b-navbar-nav>
          <b-nav-item @click="gotoRoute('cruisePlanningList')">Planificare Curse</b-nav-item>
        </b-navbar-nav>
         <b-navbar-nav>
          <b-nav-item @click="gotoRoute('reservations')">Rezervari</b-nav-item>
        </b-navbar-nav>

      </div>
    </b-collapse>
  </b-navbar>
</template>

<script>
import {
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
  BNavbarNav,
  BCollapse
} from "bootstrap-vue";

export default {
  name: 'RomMobileMenu',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BNavbarNav,
    BCollapse,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    gotoRoute(routeName) {
      this.$router.push({name: routeName});
    },
    home() {
      this.$router.push('/');
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.user;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
  .md-app {
    min-height: 100%;

    .md-content {
      padding: 0;
      border:none;
    }
  }
  .md-drawer {
    width: 200px;
    max-width: calc(100vw - 125px);
  }
</style>
