/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/routes-files', { params });
  },
  get(id) {
    return ApiService.get('/route-file', id);
  },
  getBySlug(slug) {
    return ApiService.getBySlug('/routes-files', slug);
  },
  create(data) {
    return ApiService.post('/route-file', data);
  },
  update(data) {
    return ApiService.update('/route-file', data.id, data);
  },
  close(id) {
    return ApiService.put(`/route-file/${id}/close`);
  },
  destroy(id) {
    return ApiService.delete(`/routes-files/${id}`);
  },
  createLogbooks(routeFileId, data) {
    return ApiService.post('/route-file/' + routeFileId + '/driver-logbooks', data);
  },
  updateLogbooks(routeFileId, data) {
    return ApiService.put('/route-file/' + routeFileId + '/driver-logbooks', data);
  },
  getLogbooksForRouteFileId(routeFileId) {
    return ApiService.getFullPath('/route-file/' + routeFileId + '/driver-logbooks');
  },
  getPredefinedStations(params) {
    return ApiService.query('/predefined-routes/stations', { params });
  },
};
