/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';
import {ur} from "vuejs-datepicker/dist/locale";

export default {
  downloadReport(reportName) {
    let url;
    switch (reportName) {
      case 'expiredDriverDocuments':
        url = '/exports/drivers/expired-documents';
        break;
      case 'driversStatus':
        url = '/exports/drivers/status';
        break;
      case 'foiDeParcurs':
        url = '/exports/logbooks';
        break;
    }
    return ApiService.downloadExcel(url);
  },
};
