import Vue from 'vue';
import Notifications from 'vue-notification';
import Toasted from 'vue-toasted';
import Vuelidate from 'vuelidate';
import VueLogger from 'vuejs-logger';
import VueSimpleAlert from "vue-simple-alert";
import ApiService from '@/services/api.service';
import '@/plugins';
import i18n from '@/plugins/i18n';
import router from './router';
import store from './store';
import App from './App.vue';
import './jquery';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.use(Toasted);
Vue.use(require('vue-moment'));
Vue.use(Vuelidate);
Vue.use(VueSimpleAlert);
Vue.use(Notifications);

const isProduction = process.env.NODE_ENV === 'production';

const loggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.use(VueLogger, loggerOptions);

Vue.config.productionTip = false;

ApiService.init();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
