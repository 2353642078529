<template>
  <div>
    <div class="layout-row">
      <rom-sidebar/>
      <div class="container-fluid">
        <rom-mobile-menu/>
        <md-app id="app" md-mode="fixed">
          <md-app-content class="pt-5">
            <router-view></router-view>

          </md-app-content>
        </md-app>
      </div>
    </div>
    <notifications classes="notifications" group="errors" />
    <notifications classes="notifications-success" group="success" />
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  import RomSidebar from "./components/layout/RomSidebar";
  import RomMobileMenu from "./components/layout/RomMobileMenu";

  export default {
    name: 'RomfourApp',
    components: {
      RomMobileMenu,
      RomSidebar,
      Datepicker,
    },
    computed: {},
    created() {},
    methods: {},
  }

</script>
