/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import UsersService from '@/services/users.service';

import {
  FETCH_USERS
} from './actions.type';

import {
  FETCH_USERS_END,
} from './mutations.type';


export default {
  state: {
    users: [],
  },
  getters: {
    users(state) {
      return state.users;
    },
  },
  mutations: {
    [FETCH_USERS_END](state, response) {
      state.users = response.data;
    },
  },
  actions: {
    [FETCH_USERS](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };

        if (filters && filters.username && filters.username != '') {
          query.username = ['like', filters.username];
        }
        if (filters && filters.status && filters.status != '') {
          query.status = ['=', filters.status];
        }

        // do the actual API call
        UsersService.query(query)
          .then((response) => {
            context.commit(FETCH_USERS_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
  },
};
