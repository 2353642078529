/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/vehicles', { params });
  },
  getExternalVehicles(params) {
    return ApiService.query('/external-vehicles', { params });
  },
  getTypes() {
    return ApiService.query('/vehicles/types', {page: 0, size: 1000});
  },
  getClasses() {
    return ApiService.query('/vehicles/classes', {page: 0, size: 1000});
  },
  get(vehicleId) {
    return ApiService.get('/vehicle', vehicleId);
  },
  update(vehicleId, data) {
    return ApiService.post(`/vehicle/${vehicleId}`, data);
  },
};
