/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { API_URL, APP_API_KEY } from '@/config';
import AuthService from '@/services/auth.service';
import router from '@/router';
import store from '@/store';
import { PURGE_AUTH } from '@/store/mutations.type';

// Add a request interceptor
axios.interceptors.request.use(
  (cfg) => {
    if (AuthService.getToken()) {
      cfg.headers['X-AUTH-TOKEN'] = AuthService.getToken();
    }

    cfg.headers['X-AUTH-SECRET'] = APP_API_KEY;

    return cfg;
  },
  (err) => {
   Vue.notify({
     group: 'errors',
     type: 'error',
     title: 'REQUEST INTERCEPTOR' + err,
   })
    return Promise.reject(err);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  (res) => {
    console.log('RESPONSE INTERCEPTOR', res);
    return res;
  },
  (err) => {
    // NOT LOGGED IN
   Vue.notify({
     group: 'errors',
     type: 'error',
     title: err,
     duration: -1,
     });

    if (err.response.status === 401) {
     Vue.notify({
       group: 'errors',
       type: 'error',
       title: 'RESPONSE STATUS :: 401',
       duration: -1,
     })
      store.commit(PURGE_AUTH);
    }

    // NOT ENOUGH PERMISSIONS
    if (err.response.status === 403) {
      Vue.notify({
        group: 'errors',
        type: 'error',
        title: 'RESPONSE STATUS :: 403',
        duration: -1,
      })
      router.push({ name: 'home' });
    }

    if (err.response.status === 404) {
      Vue.notify({
        group: 'errors',
        type: 'error',
        title: 'RESPONSE STATUS :: 404',
        duration: -1,
      })
      router.push({ name: 'not-found' });
    }

    if (err.response.status === 422) {
      Vue.notify({
        group: 'errors',
        type: 'error',
        title: 'RESPONSE STATUS :: 422',
        text: err.response.data,
        duration: -1,
      })
    }
    return Promise.reject(err);
  },
);

export default {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },
  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });
  },
  get(resource, id) {
    return Vue.axios.get(`${resource}/${id}`).catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });
  },
  getFullPath(resource) {
    return Vue.axios.get(`${resource}`).catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });
  },
  getBySlug(resource, slug) {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });
  },
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });
  },
  downloadExcel(resource) {
    return Vue.axios.post(resource, null,
      {
        headers:
          {
            // 'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          },
        responseType: 'arraybuffer',
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.xlsx');
      document.body.appendChild(link);
      link.click();
      return response;
    })
      .catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });

  },
  downloadFile(resource, filename) {
    return Vue.axios.get(resource,
      {
        responseType: 'blob',
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
      .catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });
  },
  getFileContent(resource) {
    return Vue.axios.get(resource,
      {
        responseType: 'blob',
      }
    ).then((response) => {
      return window.URL.createObjectURL(new Blob([response.data]));
    })
      .catch((error) => {
      throw new Error(`[REC] ApiService ${error}`);
    });
  },
};
