/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import routeDefinitionsService from '@/services/routeDefinitions.service';

import {
  FETCH_TIPURI_RUTE,
  FETCH_ROUTEDEFINITIONS,
  FETCH_ROUTEDEFINITION,
  ROUTEDEFINITION_CREATE_SUBMIT,
  ROUTEDEFINITION_EDIT_SUBMIT,
  ROUTEDEFINITION_DELETE_SUBMIT, FETCH_VEHICLECOURSETYPE, FETCH_LOCALITIES, HUB_DELETE_SUBMIT,
} from '../actions.type';

import {
  FETCH_ROUTEDEFINITIONS_END,
  FETCH_ROUTEDEFINITION_END,
  UPDATE_FILTER_ROUTEDEFINITIONS_M,
  FETCH_LOCALITIES_END,
  ROUTEDEFINITION_INIT_NEW_FORM,
} from '../mutations.type';
import VehiclesCourseTypeService from "@/services/vehiclesCourseType.service";
import HubsService from "@/services/hubs.service";

export default {
  state: {
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    newRoutDefinitionForm: {},
    editRoutDefinitionsForm: {},
    deleteRoutDefinitionsForm: {},
    RoutDefinitionsData:[],
  },
  getters: {
    routeDefinitionsItems(state) {
      return state.data;
    },
    routeDefinitionsPerPage(state) {
      return state.perPage;
    },
    routeDefinitionsCurrentPage(state) {
      return state.currentPage;
    },
    routeDefinitionsLastPage(state) {
      return state.numPages;
    },
    routeDefinitionsNextPage(state) {
      return state.nextPage;
    },
    routeDefinitionsPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    routeDefinitionsFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    routeDefinitionsTo(state) {
      return state.perPage * state.currentPage;
    },
    routeDefinitionsTotal(state) {
      return state.total;
    },
    newrouteDefinitionForm(state) {
      return state.newrouteDefinitionForm;
    },
    editrouteDefinitionForm(state) {
      return state.editrouteDefinitionForm;
    },
    routeDefinitionData(state) {
      return state.routeDefinitionData;
    },
  },
  setters: {
    initNewRouteDefinitionForm(state) {
      state.newRouteDefinitionForm = {};
    },
    routeDefinitionsCurrentPage(state, value) {
      state.currentPage = value;
    }
  },
  mutations: {
    [FETCH_ROUTEDEFINITIONS_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
    [FETCH_ROUTEDEFINITION_END](state, response) {
      state.routeDefinition = response.data;
    },
    [FETCH_LOCALITIES_END](state, response) {
      state.routeDefinition = response.data;
    },
    [UPDATE_FILTER_ROUTEDEFINITIONS_M](state, data) {
      Vue.set(state.filters, data);
    },
  },
  actions: {
    [FETCH_ROUTEDEFINITIONS](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters, sort } = params;
        let query = { page, perPage };

        if (filters && filters.id && filters.id != '') {
          query.id = ['=', filters.id];
        }
        if (filters && filters.tblTipuriRuteId && filters.tblTipuriRuteId != '') {
          query.tblTipuriRuteId = ['=', filters.tblTipuriRuteId];
        }
        if (filters && filters.routeType && filters.routeType != '') {
          query.routeType = ['=', filters.routeType];
        }
        if (filters && filters.trackType && filters.trackType != '') {
          query.trackType = ['=', filters.trackType];
        }
        if (filters && filters.transportType && filters.transportType != '') {
          query.transportType = ['=', filters.transportType];
        }
        if (sort && sort.column) {
          query.sortBy = sort.column;
          query.sortDir = sort.dir;
        }
        // do the actual API call
        routeDefinitionsService.query(query)
          .then((response) => {
            context.commit(FETCH_ROUTEDEFINITIONS_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_TIPURI_RUTE](context) {
      return new Promise((resolve, reject) => {
        routeDefinitionsService.getTipuriRute()
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_ROUTEDEFINITION](context, slug) {
      return new Promise((resolve, reject) => {
        routeDefinitionsService.get(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_ROUTEDEFINITION](context, slug) {
      return new Promise((resolve, reject) => {
        routeDefinitionsService.get(slug)
          .then((response) => {
            context.commit(FETCH_ROUTEDEFINITION_END, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ROUTEDEFINITION_CREATE_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        routeDefinitionsService.create(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ROUTEDEFINITION_EDIT_SUBMIT](context, data) {
      return new Promise((resolve, reject) => {
        routeDefinitionsService.update(data.id, data.formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ROUTEDEFINITION_INIT_NEW_FORM](state) {
      state.newRoutDefinitionForm = {};
    },
    [ROUTEDEFINITION_DELETE_SUBMIT](context, routeDefinitionId) {
      return new Promise((resolve, reject) => {
        routeDefinitionsService.destroy(routeDefinitionId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_LOCALITIES](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters, sort } = params;
        let query = { page, perPage };
        if (filters && filters.numeLocalitate && filters.numeLocalitate != '') {
          query.numeLocalitate = ['like', filters.numeLocalitate];
        }
        // if (filters && filters.routeType && filters.routeType != '') {
        //   query.routeType = ['=', filters.routeType];
        // }
        if (sort && sort.column) {
          query.sortBy = sort.column;
          query.sortDir = sort.dir;
        }
        // do the actual API call
        routeDefinitionsService.getLocalities(query)
          .then((response) => {
            context.commit(FETCH_LOCALITIES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
  },
};
