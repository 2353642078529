import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';
import Role from '@/enums/userRoles';
import {
  VALIDATE_ROMFOUR_SESSION,
} from '@/store/actions.type';
import { ROMFOUR_URL } from '@/config';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'drivers-home',
    meta: {
      showProgressBar: false,
      authorize: [Role.superAdmin, Role.admin, Role.operator],
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Drivers/ListPage.vue'),
  },
  {
    path: '/drivers',
    name: 'drivers',
    component: () => import(/* webpackChunkName: "about" */ '../views/Drivers/ListPage.vue'),
  },
  {
    path: '/driver/new',
    name: 'driverNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/Drivers/DriverNewPage.vue'),
  },
  {
    path: '/drivers/:slug/edit',
    name: 'driverEdit',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Drivers/DriverEditPage.vue'),
    meta: {
      showProgressBar: false,
      authorize: [Role.superAdmin, Role.admin, Role.operator],
    },
  },
  {
    path: '/route-files',
    name: 'routeFiles',
    component: () => import(/* webpackChunkName: "about" */ '../views/Routes/RouteFilesListPage.vue'),
    meta: {
      showProgressBar: false
    }
  },
  {
    path: '/route-files/new',
    name: 'routeFilesNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/Routes/RouteFileNewPage.vue'),
    meta: {
      showProgressBar: false
    }
  },
  {
    path: '/route-files/edit/:slug',
    name: 'routeFilesEdit',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Routes/RouteFileEditPage.vue'),
    meta: {
      showProgressBar: false
    }
  },
  {
    path: '/route-files/:slug/logbooks',
    name: 'routeFilesLogbooks',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Routes/RouteFileNewLogbooksPage.vue'),
    meta: {
      showProgressBar: false,
      authorize: [Role.superAdmin, Role.admin, Role.operator],
    }
  },
  {
    path: '/drivers/:slug/edit/drivers-documents',
    name: 'driverDocument',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Drivers/DriversDocumentsPage.vue'),
  },
  {
    path: '/employee/:slug/leave-requests',
    name: 'employeeLeaveRequests',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Drivers/DriverLeaveRequestPage.vue'),
    meta: {
      showProgressBar: false,
      authorize: [Role.superAdmin, Role.admin, Role.operator],
    },
  },
  {
    path: '/vehicles/:slug/edit',
    name: 'vehicleEdit',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Vehicles/VehicleEditPage.vue'),
    meta: {
      showProgressBar: false,
      authorize: [Role.superAdmin, Role.admin, Role.operator],
    },
  },
  {
    path: '/vehicles',
    name: 'vehiclesList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Vehicles/VehiceListPage.vue'),
    meta: {
      showProgressBar: false
    }
  },
  {
    path: '/vehicles-folders/hubs',
    name: 'hubs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Hubs/ListPage.vue'),
  },
  {
    path: '/vehicles-folders/hub/new',
    name: 'hubNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/Hubs/HubNewPage.vue'),
  },
  {
    path: '/vehicles-folders/hubs/:slug/edit',
    name: 'hubEdit',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Hubs/HubEditPage.vue'),
    meta: {
      showProgressBar: false,
      authorize: [Role.superAdmin, Role.admin, Role.operator],
    },
  },
  {
    path: '/vehicles-folders',
    name: 'vehiclesFolders',
    component: () => import(/* webpackChunkName: "about" */ '../views/VehiclesCourseType/ListPage.vue'),
  },
  {
    path: '/vehicles-folders/vehicles-course-type',
    name: 'vehiclesCourseType',
    component: () => import(/* webpackChunkName: "about" */ '../views/VehiclesCourseType/ListPage.vue'),
  },
  {
    path: '/vehicles-folders/vehicles-course-type/:slug/edit',
    name: 'vehiclesCourseTypeEdit',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/VehiclesCourseType/EditPage.vue'),
    meta: {
      showProgressBar: false,
      authorize: [Role.superAdmin, Role.admin, Role.operator],
    },
  },
  {
    path: '/vehicles-folders/vehicles-status',
    name: 'vehicleStatus',
    component: () => import(/* webpackChunkName: "about" */ '../views/VehicleStatus/VehicleStatusListPage.vue'),
  },
  {
    path: '/vehicles-folders/current-vehicle-status-list/:slug',
    name: 'currentVehicleStatusListPage',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/VehicleStatus/CurrentVehicleStatusListPage.vue'),
    meta: {
      showProgressBar: false,
    },
  },
  //Route Definitions
  {
    path: '/vehicles-folders/route-definitions',
    name: 'routeDefinitions',
    component: () => import(/* webpackChunkName: "about" */ '../views/RouteDefinitions/RouteDefinitionListPage/RouteDefinitionListPage.vue'),
  },
  {
    path: '/vehicles-folders/route-definition/new',
    name: 'routeDefinitionNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/RouteDefinitions/RouteDefinitionNewPage/RouteDefinitionNewPage.vue'),
  },
  {
    path: '/vehicles-folders/route-definition/:slug/edit',
    props: true,
    name: 'routeDefinitionEdit',
    meta: {
      showProgressBar: false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/RouteDefinitions/RouteDefinitionEditPage/RouteDefinitionEditPage.vue'),
  },
  {
    path: '/vehicles-folders/route-definition/cruise-planning/:slug',
    props: true,
    name: 'cruisePlanning',
    meta: {
      showProgressBar: false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CruisePlanning/CruisePlanningCreate/CruisePlanningCreate.vue'),
  },
  {
    path: '/vehicles-folders/route-definition/cruise-planning-edit/:slug',
    props: true,
    name: 'cruisePlanningEdit',
    meta: {
      showProgressBar: false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CruisePlanningEdit/CruisePlanningEdit/CruisePlanningEdit.vue'),
  },
  {
    path: '/vehicles-folders/route-definition/cruise-planning/view/:slug',
    props: true,
    name: 'cruisePlanningView',
    meta: {
      showProgressBar: false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CruisePlanningView/CruisePlanningView.vue'),
  },
  {
    path: '/vehicles-folders/route-definition/new-ride/:slug',
    props: true,
    name: 'Createnewride',
    meta: {
      showProgressBar: false,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateNewRide/CreateNewRide.vue'),
  },
  {
    path: '/vehicles-folders/reservations',
    name: 'reservations',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reservations/ReservationListPage/ReservationListPage.vue'),
  },
  {
    path: '/vehicles-folders/reservation/:slug',
    name: 'reservation',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/Reservations/ReservationDetailPage/ReservationDetailPage.vue'),
    meta: {
      showProgressBar: false,
    },
  },
  {
    path: '/vehicles-folders/cruises/cruiseList',
    name: 'cruiseList',
    component: () => import(/* webpackChunkName: "about" */ '../views/CruiseList/CruiseList.vue'),
  },
  {
    path: '/vehicles-folders/cruises/cruisePlanningList',
    name: 'cruisePlanningList',
    component: () => import(/* webpackChunkName: "about" */ '../views/CruisePlanningList/CruisePlanningList.vue'),
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
  },
  // // a param can be made optional by adding "?"
  // { path: '/optional-params/:foo?' },
  // // a param can be followed by a regex pattern in parens
  // // this route will only be matched if :id is all numbers
  // { path: '/params-with-regex/:id(\\d+)' },
  // // asterisk can match anything
  // { path: '/asterisk/*' },
  // // make part of the path optional by wrapping with parens and add "?"
  // { path: '/optional-group/(foo/)?bar' }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('====================');
  console.log('router.BeforeEach');
  console.log('FROM :: ', from);
  console.log('TO :: ', to);
  console.log('NEXT :: ', next);
  console.log('====================');

  const { authorize } = to.meta;
  let authenticated = store.getters.isAuthenticated;
  const currentUser = store.getters.user;
  if (authorize) {
    //force authentication
    if (from.query.sessionId || to.query.sessionId) {
      authenticated = false;
    }

    if (!authenticated) {
      let sessionId = from.query.sessionId || to.query.sessionId;
      if (!sessionId) {
        let match = document.cookie.match('RFRSESID');
        sessionId =  match ? match[1] : null;
      } else {
        let d = new Date();
        d.setTime(d.getTime() + (3 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = "RFRSESID=" + sessionId + ";" + expires + ";path=/";
      }

      if (!sessionId) {
        return next({ path: 'neautentificat' });
        // return next({ path: ROMFOUR_URL });
      }

      store.dispatch(VALIDATE_ROMFOUR_SESSION, {sessionId}).then(() => {
      }).catch((error) => {
        this.$log.error('VALIDATE_ROMFOUR_SESSION', error);
      });
    }

    if (currentUser && authenticated && authorize.length && !authorize.filter(x => currentUser.roles.includes(x))) {
      // role not authorised so redirect to home page - should logoff nad login with the good user before trying again
      return next({ path: '/' });
    }
  }

  return next();
});

router.afterEach(() => {
  // console.log('====================');
  // console.log('router.BeforeEach');
  // console.log('FROM :: ', from);
  // console.log('TO :: ', to);
  // console.log('isAuthenticated', store.getters.isAuthenticated);
  // console.log('====================');
});

export default router;
