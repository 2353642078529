/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  queryBranches(params) {
    return ApiService.query('/company/branches', { params });
  },
  queryDepartments(params) {
    return ApiService.query('/company/departments', { params });
  },
  queryCashRegisters(params) {
    return ApiService.query('/company/cash-registers', { params });
  },
};
