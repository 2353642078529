/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import VehiclesService from '@/services/vehicles.service';

import {
  FETCH_VEHICLE,
  FETCH_VEHICLES,
  FETCH_VEHICLES_FOR_EXTERNAL,
  FETCH_VEHICLES_CLASSES,
  FETCH_VEHICLES_TYPES,
  UPDATE_FILTER_VEHICLES,
  VEHICLE_EDIT_SUBMIT,
} from '../actions.type';

import {
  FETCH_VEHICLES_END,
  FETCH_VEHICLES_TYPES_END,
  FETCH_VEHICLES_CLASSES_END,
  UPDATE_FILTER_VEHICLES_M, FETCH_DRIVER_END,
} from '../mutations.type';
import {isNullOrUndefined} from "@tinymce/tinymce-vue/lib/cjs/main/ts/Utils";

export default {
  state: {
    filters: {
      vehicleType: null,
      vehicleClass: null,
      vehicleStatus: null,
      licensePlate: null,
      numarSasiu: null,
    },
    availableVehicleClasses: [],
    availableVehicleTypes: [],
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    sort: [],

  },
  getters: {
    vehiclesItems(state) {
      return state.data;
    },
    vehiclesPerPage(state) {
      return state.perPage;
    },
    vehiclesCurrentPage(state) {
      return state.currentPage;
    },
    vehiclesLastPage(state) {
      return state.numPages;
    },
    vehiclesNextPage(state) {
      return state.nextPage;
    },
    vehiclesPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    vehiclesFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    vehiclesTo(state) {
      return state.perPage * state.currentPage;
    },
    vehiclesTotal(state) {
      return state.total;
    },
    vehiclesFilters(state) {
      return state.filters;
    },
    vehicleTypes(state) {
      return state.availableVehicleTypes;
    },
    vehicleClasses(state) {
      return state.availableVehicleClasses;
    },
  },
  mutations: {
    [FETCH_VEHICLES_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
    [FETCH_VEHICLES_TYPES_END](state, response) {
      state.availableVehicleTypes = response.data;
    },
    [FETCH_VEHICLES_CLASSES_END](state, response) {
      state.availableVehicleClasses = response.data;
    },

    [UPDATE_FILTER_VEHICLES_M](state, data) {
      Vue.set(state.filters, data);
    },
  },
  actions: {
    [FETCH_VEHICLES](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };
        if (filters && filters.vehicleStatus && filters.vehicleStatus != '') {
          query.vehicleStatus = ['=', filters.vehicleStatus];
        }
        if (filters && filters.vehicleType && filters.vehicleType != '') {
          query.type = ['=', filters.vehicleType];
        }
        if (filters && filters.vehicleClass && filters.vehicleClass != '') {
          query.class = ['=', filters.vehicleClass];
        }
        if (filters && filters.licensePlate && filters.licensePlate != '') {
          query.licenseNumber = ['like', filters.licensePlate];
        }
        if (filters && filters.vehicleAvailabilityStatus && filters.vehicleAvailabilityStatus != '') {
          query.vehicleAvailabilityStatus = ['=', filters.vehicleAvailabilityStatus];
        }
        if (filters && !isNullOrUndefined(filters.driverEmployeeId)) {
          query.driverEmployeeId = ['=', filters.driverEmployeeId];
        }
        if (filters && !isNullOrUndefined(filters.numarSasiu)) {
          query.numarSasiu = ['like', filters.numarSasiu];
        }

        // do the actual API call
        VehiclesService.query(query)
          .then((response) => {
            context.commit(FETCH_VEHICLES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    //This is used on CruisePlanningCreateStep3 in RomfourVehiclesCruisePlanning componenent to generate only the car
    //that are available for external cruises
    [FETCH_VEHICLES_FOR_EXTERNAL](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };
        if (filters && filters.vehicleStatus && filters.vehicleStatus != '') {
          query.vehicleStatus = ['=', filters.vehicleStatus];
        }
        if (filters && filters.vehicleType && filters.vehicleType != '') {
          query.type = ['=', filters.vehicleType];
        }
        if (filters && filters.postalCode && filters.postalCode != '') {
          query.codPostal2 = ['like', filters.postalCode];
        }
        if (filters && filters.vehicleClass && filters.vehicleClass != '') {
          query.class = ['=', filters.vehicleClass];
        }
        if (filters && filters.licensePlate && filters.licensePlate != '') {
          query.licenseNumber = ['like', filters.licensePlate];
        }
        if (filters && filters.vehicleStatus && filters.vehicleStatus != '' && filters.vehicleStatus == 'transferVehicle') {
          query.vehicleStatus = ['like', filters.vehicleStatus];
        }
        if (filters && filters.vehicleAvailabilityStatus && filters.vehicleAvailabilityStatus != '') {
          query.vehicleAvailabilityStatus = ['=', filters.vehicleAvailabilityStatus];
        }
        if (filters && !isNullOrUndefined(filters.driverEmployeeId)) {
          query.driverEmployeeId = ['=', filters.driverEmployeeId];
        }

        // do the actual API call
        VehiclesService.getExternalVehicles(query)
          .then((response) => {
            context.commit(FETCH_VEHICLES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_VEHICLES_TYPES](context) {
      return new Promise((resolve, reject) => {
        // do the actual API call
        VehiclesService.getTypes()
          .then((response) => {
            context.commit(FETCH_VEHICLES_TYPES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
    [FETCH_VEHICLES_CLASSES](context) {
      return new Promise((resolve, reject) => {
        // do the actual API call
        VehiclesService.getClasses()
          .then((response) => {
            context.commit(FETCH_VEHICLES_CLASSES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
    [VEHICLE_EDIT_SUBMIT](context, params) {
      return new Promise((resolve, reject) => {

        VehiclesService.update(params.vehicleId, params.formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [FETCH_VEHICLE](context, slug) {
      return new Promise((resolve, reject) => {
        VehiclesService.get(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [UPDATE_FILTER_VEHICLES](context, params) {
      context.commit(UPDATE_FILTER_VEHICLES_M, params);
    },
  },
};
