/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import VehicleStatus from '@/services/vehicleStatus.service';

import {
  FETCH_VEHICLES_STATUS,
  UPDATE_FILTER_VEHICLES_STATUS,
  ADD_VEHICLE_STATUS, FETCH_VEHICLECOURSETYPE,
  FETCH_VEHICLE_STATUS, UPDATE_VEHICLE_STATUS,
} from '../actions.type';

import {
  FETCH_VEHICLES_STATUS_END, UPDATE_FILTER_VEHICLES_STATUS_M,
} from '../mutations.type';
import {isNullOrUndefined} from "@tinymce/tinymce-vue/lib/cjs/main/ts/Utils";
import moment from 'moment';
import VehiclesCourseTypeService from "@/services/vehiclesCourseType.service";
export default {
  state: {
    filters: {
      status: null,
      subStatus: null,
      vehicleId: null,
      numberPlate: null,
      startAt: null,
      endAt: null,
      createdBy : null,
      updatedBy: null,
    },
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    sort: [],

  },
  getters: {
    vehiclesStatusItems(state) {
      return state.data;
    },
    vehiclesStatusPerPage(state) {
      return state.perPage;
    },
    vehiclesStatusCurrentPage(state) {
      return state.currentPage;
    },
    vehiclesStatusLastPage(state) {
      return state.numPages;
    },
    vehiclesStatusNextPage(state) {
      return state.nextPage;
    },
    vehiclesStatusPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    vehiclesStatusFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    vehiclesStatusTo(state) {
      return state.perPage * state.currentPage;
    },
    vehiclesStatusTotal(state) {
      return state.total;
    },
    vehiclesStatusFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    [FETCH_VEHICLES_STATUS_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
  },
  actions: {
    [FETCH_VEHICLES_STATUS](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };
        if (filters && filters.status && filters.status != '') {
          query.status = ['=', filters.status];
        }
        if (filters && filters.numberPlate && filters.numberPlate != '') {
          query.numberPlate = ['like', filters.numberPlate];
        }
        if (filters && filters.startAt && filters.startAt != '') {
          query.startAt = ['d>=', moment(filters.startAt.toISOString()).format('DD/MM/YYYY')];
        }
        if (filters && filters.endAt && filters.endAt != '') {
          query.endAt = ['<=', moment(filters.endAt.toISOString()).format('DD/MM/YYYY')];
        }
        if (filters && filters.vehicleId && filters.vehicleId != '') {
          query.vehicleId = ['=', filters.vehicleId];
        }
        // do the actual API call
        VehicleStatus.query(query)
          .then((response) => {
            context.commit(FETCH_VEHICLES_STATUS_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
          });
      });
    },
    [ADD_VEHICLE_STATUS](context, params) {
      return new Promise((resolve, reject) => {
        VehicleStatus.create(params.vehicleId, params.item)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_VEHICLE_STATUS](context, slug) {
      return new Promise((resolve, reject) => {
        VehicleStatus.get(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_VEHICLE_STATUS](context, params) {
      return new Promise((resolve, reject) => {
        VehicleStatus.update(params.vehicleId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [UPDATE_FILTER_VEHICLES_STATUS](context, params) {
      context.commit(UPDATE_FILTER_VEHICLES_STATUS_M, params);
    },
  },
};
