/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  // query(params) {
  //   return ApiService.query('/cruises', { params });
  // },
  getSavedReservationByCruiseId(id) {
    return ApiService.get('/cruise-reservations', id);
  },
  get(id) {
    return ApiService.get('/cruise-reservations/vehicles', id);
  },
  getReservationVehicleName(id) {
    return ApiService.get('cruise-reservations/reservation/vehicleName', id);
  },
  create(data) {
    return ApiService.post('/cruise-reservations', data);
  },
  saveDrivers(data) {
    return ApiService.post('/cruise-reservations/save-drivers', data);
  },
  mainVehicleSubmit(data) {
    return ApiService.post('/cruise-reservations/main-vehicle', data);
  },
  reservationsEdit(data) {
    return ApiService.post('/cruise-reservations/edit-reservations', data);
  },
  mainTrailerVehicleSubmit(data) {
    return ApiService.post('/cruise-reservations/main-trailer-vehicle', data);
  },
  mainBackupVehicleSubmit(data) {
    return ApiService.post('/cruise-reservations/main-backup-vehicle', data);
  },
  transferVehicleSubmit(data) {
    return ApiService.post('/cruise-reservations/transfer-vehicle', data);
  },
  getReservationDrivers(id) {
    return ApiService.get('/cruise-reservations/get-drivers', id);
  },
  destoryReservationsVehiclesDrivers(id) {
    return ApiService.delete(`/cruise-reservations/deleteAllRecords/${id}`);
  },
  destroy(id) {
    return ApiService.delete(`/cruise-reservations/delete/${id}`);
  },
  destroyDrivers(id) {
    return ApiService.delete(`/cruise-reservations/delete-drivers/${id}`);
  },
};
