/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import CruisesService from '@/services/cruises.service';

import {
  FETCH_CRUISES,
  FETCH_CRUISE,
  CRUISE_CREATE_SUBMIT,
  CRUISE_EDIT_SUBMIT,
  CRUISE_DELETE_SUBMIT,
  CRUISE_INIT_NEW_FORM,
  CRUISE_RESERVATIONS_VEHICLES_CREATE,
  FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS,
  FETCH_DRIVERS_ON_VEHICLES, DELETE_DRIVERS_VEHICLE,
  CRUISE_DELETE,
  CRUISE_STATUS_SUBMIT
} from '../actions.type';

import {
  FETCH_CRUISES_END,
  FETCH_CRUISE_END,
  UPDATE_FILTER_CRUISES_M,
  FETCH_CRUISE_VEHICLES_DRIVERS_END,
  FETCH_CRUISE_RESERVATIONS_VEHICLES_END,
  FETCH_DRIVERS_ON_VEHICLES_END,
} from '../mutations.type';
import moment from "moment";

export default {
  state: {
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    newHubForm: {},
    editHubForm: {},
    deleteHubForm: {},
    hubData:[],
  },
  getters: {
    cruisesItems(state) {
      return state.data;
    },
    cruisesPerPage(state) {
      return state.perPage;
    },
    cruisesCurrentPage(state) {
      return state.currentPage;
    },
    cruisesLastPage(state) {
      return state.numPages;
    },
    cruisesNextPage(state) {
      return state.nextPage;
    },
    cruisesPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    cruisesFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    cruisesTo(state) {
      return state.perPage * state.currentPage;
    },
    cruisesTotal(state) {
      return state.total;
    },
    newCruiseForm(state) {
      return state.newCruiseForm;
    },
    editCruiseForm(state) {
      return state.editCruiseForm;
    },
    cruiseData(state) {
      return state.cruiseData;
    },
  },
  setters: {
    initNewCruiseForm(state) {
      state.newCruiseForm = {};
    },
    cruisesCurrentPage(state, value) {
      state.currentPage = value;
    }
  },
  mutations: {
    [FETCH_CRUISES_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
    [FETCH_CRUISE_END](state, response) {
      state.hub = response.data;
    },

    [UPDATE_FILTER_CRUISES_M](state, data) {
      Vue.set(state.filters, data);
    },
  },
  actions: {
    [FETCH_CRUISES](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters, sort } = params;
        let query = { page, perPage };

        if (filters && filters.id && filters.id != '') {
          query.id = ['like', filters.id];
        }
        if (filters && filters.date && filters.date != '') {
          query.date = ['>=', moment(filters.date.toISOString()).format('YYYY-MM-DD')];
        }
        if (filters && filters.codIdentificare && filters.codIdentificare != '') {
          query.identification_code = ['like', filters.codIdentificare];
        }
        if (filters && filters.trackType && filters.trackType != '') {
          query.trackType = ['=', filters.trackType];
        }
        if (filters && filters.status && filters.status != '') {
          query.status = ['=', filters.status];
        }
        if (sort && sort.column) {
          query.sortBy = sort.column;
          query.sortDir = sort.dir;
        }
        // do the actual API call
        CruisesService.query(query)
          .then((response) => {
            context.commit(FETCH_CRUISES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_CRUISE](context, slug) {
      return new Promise((resolve, reject) => {
        CruisesService.get(slug)
          .then((response) => {
            context.commit(FETCH_CRUISE_END, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS](context, slug) {
      return new Promise((resolve, reject) => {
        CruisesService.getCruiseWithVehiclesAndDrivers(slug)
          .then((response) => {
            context.commit(FETCH_CRUISE_RESERVATIONS_VEHICLES_END, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [CRUISE_CREATE_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        CruisesService.create(formData)
          .then((response) => {
            resolve(response.data);
            console.log(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_EDIT_SUBMIT](context, data) {
      return new Promise((resolve, reject) => {
        CruisesService.update(data.id, data.formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_STATUS_SUBMIT](context, data) {
      return new Promise((resolve, reject) => {
        CruisesService.updateStatus(data.id, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_DELETE_SUBMIT](context, cruiseId) {
      return new Promise((resolve, reject) => {
        CruisesService.destroy(cruiseId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_DELETE](context, cruiseId) {
      return new Promise((resolve, reject) => {
        CruisesService.destroyAll(cruiseId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_INIT_NEW_FORM](state) {
      state.newCruiseForm = {};
    },
  },
};
