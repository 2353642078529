/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import CruiseReservationsService from '@/services/cruiseReservations.service';

import {
  CRUISE_DELETE_SUBMIT,
  CRUISE_RESERVATIONS_CREATE_SUBMIT,
  CRUISE_RESERVATIONS_DELETE_SUBMIT,
  CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT,
  CRUISE_RESERVATIONS_VEHICLES_CREATE,
  FETCH_CRUISE_RESERVATIONS_VEHICLES,
  CRUISE_RESERVATIONS_VEHICLES_DELETE,
  FETCH_RESERVATION_VEHICLE_NAME,
  FETCH_SAVED_RESERVATIONS,
  FETCH_SAVED_RESERVATIONS_WITH_ID,
  CRUISE_RESERVATIONS_EDIT,
  FETHC_CRUISE_RESERVATIONS_VEHICLE_DRIVERS,
  DELETE_CRUISE_RESERVATIONS_VEHICLES_DRIVERS

} from '../actions.type';

import {
  FETCH_CRUISE_RESERVATIONS_END, FETCH_CRUISE_RESERVATIONS_VEHICLES_END, FETCH_RESERVATION_VEHICLE_NAME_END,
} from '../mutations.type';
import CruisesService from "@/services/cruises.service";
import DriversService from "@/services/drivers.service";

export default {
  state: {
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    newCruiseReservationsForm: {},
  },
  getters: {
    cruiseReservationsItems(state) {
      return state.data;
    },
    cruiseReservationsPerPage(state) {
      return state.perPage;
    },
    cruiseReservationsCurrentPage(state) {
      return state.currentPage;
    },
    cruiseReservationsLastPage(state) {
      return state.numPages;
    },
    cruiseReservationsNextPage(state) {
      return state.nextPage;
    },
    cruiseReservationsPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    cruiseReservationsFrom(state) {
      return state.perPage * (state.currentPage - 1) + 1;
    },
    cruiseReservationsTo(state) {
      return state.perPage * state.currentPage;
    },
    cruiseReservationsTotal(state) {
      return state.total;
    },
    newCruiseReservationsForm(state) {
      return state.newCruiseReservationsForm;
    },
    editCruiseReservationsForm(state) {
      return state.editCruiseReservationsForm;
    },
    cruiseReservationsData(state) {
      return state.cruiseReservationsData;
    },
  },
  setters: {
    initNewCruiseReservationsForm(state) {
      state.newCruiseReservationsForm = {};
    },
    cruiseReservationsCurrentPage(state, value) {
      state.currentPage = value;
    }
  },
  mutations: {
    [FETCH_CRUISE_RESERVATIONS_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;
    },
    [FETCH_CRUISE_RESERVATIONS_VEHICLES_END](state, response) {
      state.driver = response.data;
    },
  },
  actions: {
    [CRUISE_RESERVATIONS_CREATE_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.create(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.mainVehicleSubmit(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_RESERVATIONS_EDIT](context, formData) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.reservationsEdit(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.transferVehicleSubmit(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_CRUISE_RESERVATIONS_VEHICLES](context, slug) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.get(slug)
          .then((response) => {
            context.commit(FETCH_CRUISE_RESERVATIONS_VEHICLES_END, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_RESERVATION_VEHICLE_NAME](context, slug) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.getReservationVehicleName(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_SAVED_RESERVATIONS](context, slug) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.getSavedReservationByCruiseId(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETHC_CRUISE_RESERVATIONS_VEHICLE_DRIVERS](context, slug) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.getReservationDrivers(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DELETE_CRUISE_RESERVATIONS_VEHICLES_DRIVERS](context, slug) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.destoryReservationsVehiclesDrivers(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_RESERVATIONS_DELETE_SUBMIT](context, savedCruiseID) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.destroy(savedCruiseID)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_RESERVATIONS_VEHICLES_DELETE](context, savedCruiseID) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.destroyDrivers(savedCruiseID)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CRUISE_RESERVATIONS_VEHICLES_CREATE](context, formData) {
      return new Promise((resolve, reject) => {
        CruiseReservationsService.saveDrivers(formData)
          .then((response) => {
            resolve(response.data);
            console.log(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
