export const SET_AUTH = 'setAuthentication';
export const PURGE_AUTH = 'logOut';

export const SIDEBAR_TOGGLE_M = 'toggleSidebar';
export const SIDEBAR_CLOSE_M = 'closeSidebar';

export const IS_LOADING_M = 'isLoading';
export const FINNISHED_LOADING_M = 'finnishedLoading';

export const FETCH_ROUTE_FILES_END = 'mapRouteFiles';
export const UPDATE_ACTIVE_SORT_FIELD_ROUTE_FILES_M = 'updateActiveSortFieldRouteFilesMutation';
export const UPDATE_FILTER_ROUTE_FILES_M = 'updateFilterRouteFilesMutation';
export const UPDATE_SEARCH_ROUTE_FILES_M = 'updateSearchRouteFilesMutation';
export const CLEAR_SEARCH_ROUTE_FILES_M = 'clearSearchRouteFilesMutation';
export const ROUTE_FILES_INIT_EDIT_DATA_M = 'routeFilesInitEditDataMutation';
export const ROUTE_FILES_INIT_NEW_DATA_M = 'routeFilesInitNewDataMutation';

export const UPDATE_ACTIVE_SORT_FIELD_DRIVERS_M = 'updateActiveSortFieldDriversMutation';
export const UPDATE_FILTER_DRIVERS_M = 'updateFilterDriversMutation';
export const FETCH_DRIVERS_END = 'fetchDriversMutation';
export const FETCH_DRIVER_END = 'fetchDriverMutation';
export const DRIVER_INIT_NEW_FORM = 'initializeNewDriverForm';

export const FETCH_VEHICLES_END = 'mapVehicles';
export const FETCH_VEHICLES_TYPES_END = 'mapVehiclesTypes';
export const FETCH_VEHICLES_CLASSES_END = 'mapVehiclesClasses';
export const UPDATE_FILTER_VEHICLES_M = 'updateFilterVehiclesMutation';

export const FETCH_REGIONS_END = 'mapRegions';

export const FETCH_USERS_END = 'mapUsers';

export const FETCH_EMPLOYEE_LEAVE_REQUESTS_END = 'mapLeaveRequest';
export const FETCH_EMPLOYEE_END = 'mapEmployee';

//Hub Mutations
export const FETCH_HUBS_END = 'fetchHubsMutation';
export const UPDATE_FILTER_HUBS_M = 'updateFilterHubsMutation';
export const FETCH_HUB_END = 'fetchHubMutation';
export const HUB_INIT_NEW_FORM = 'initializeNewHubForm';

//Relation between Vehicle and Hub Mutations
export const FETCH_VEHICLESCOURSETYPE_END = 'mapVehicles';
export const UPDATE_FILTER_VEHICLESCOURSETYPE_M = 'updateFilterVehiclesMutation';
export const FETCH_VEHICLECOURSETYPE_END = 'fetchVehicleCourseTypeMutation';

export const FETCH_VEHICLES_STATUS_END = 'mapVehiclesStatus';
export const UPDATE_FILTER_VEHICLES_STATUS_M = 'updateFilterVehiclesStatusMutation';

export const FETCH_ROUTEDEFINITIONS_END = 'fetchRouteDefinitionsMutation';
export const FETCH_LOCALITIES_END = 'fetchLocalitiesMutation';
export const UPDATE_FILTER_ROUTEDEFINITIONS_M = 'updateFilterRouteDefinitionsMutation';
export const FETCH_ROUTEDEFINITION_END = 'fetchRouteDefinitionMutation';
export const ROUTEDEFINITION_INIT_NEW_FORM = 'initializeNewRouteDefinitionForm';

//Reservations
export const FETCH_RESERVATIONS_END = 'mapReservations';
export const FETCH_RESERVATIONS_CRUISE_END = 'mapReservationsCruise';
export const FETCH_RESERVATION_END = 'fetchReservationMutation';
export const UPDATE_FILTER_RESERVATIONS_M = 'updateFilterReservationsMutation';

//Cruises
export const FETCH_CRUISES_END = 'mapCruises';
export const FETCH_CRUISE_END = 'fetchCruiseMutation';
export const FETCH_CRUISE_VEHICLES_DRIVERS_END = 'fetchCruiseVehiclesDriversMutation';
export const UPDATE_FILTER_CRUISES_M = 'updateFilterCruisesMutation';
export const FETCH_CRUISE_RESERVATIONS_VEHICLES_END = 'fetchCruiseReservationsVehiclesMutation';
export const FETCH_RESERVATION_VEHICLE_NAME_END = 'fetchReservationsVehicleNameMutation';

//Cruise Reservations
export const FETCH_CRUISE_RESERVATIONS_END = 'mapCruiseReservations';
export const FETCH_CRUISE_RESERVATION_END = 'fetchCruiseReservationMutation';

//Rides
export const FETCH_RIDES_END = 'fetchRidesMutation';
export const UPDATE_FILTER_RIDES_M = 'updateFilterRidesMutation';
export const FETCH_RIDE_END = 'fetchRideMutation';
