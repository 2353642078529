/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/cruises', { params });
  },
  get(id) {
    return ApiService.get('/cruise', id);
  },
  getCruiseWithVehiclesAndDrivers(id) {
    return ApiService.get('/cruise-details', id);
  },
  create(data) {
    return ApiService.post('/cruise', data);
  },
  update(id, data) {
    return ApiService.post(`/cruise/${id}`, data);
  },
  updateStatus(id, data) {
    return ApiService.post(`/cruise/updateStatus/${id}`, data);
  },
  destroy(id) {
    return ApiService.delete(`/cruise/${id}`);
  },
  destroyAll(id) {
    return ApiService.delete(`/cruise-all/${id}`);
  },
};
