import Vue from 'vue';

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
// Default white theme
// import 'vue-material/dist/theme/default.css';
// Default dark theme
// import 'vue-material/dist/theme/default-dark.css';

// Custom dark theme
import '@/assets/scss/custom-theme.scss';

// TODO :: Razvan :: Improve only the used components to be used
// https://vuematerial.io/getting-started
// Section Usage -> red info
Vue.use(VueMaterial);
