/* eslint-disable import/no-cycle,no-shadow */
import ApiService from '@/services/api.service';
import AuthService from '@/services/auth.service';
import router from '@/router/index';
import {
  VALIDATE_ROMFOUR_SESSION,
} from './actions.type';
import { SET_AUTH, PURGE_AUTH } from './mutations.type';

const state = {
  user: AuthService.getUser(),
  token: AuthService.getToken(),
  isAuthenticated: !!AuthService.getToken()
};

const getters = {
  user(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  permissions(state) {
    let permissions = {
        createEditDriver: false,
        createEditVehicle: false,
        createEditRouteFile: false,
        createEditOlderLeaveRequests: false,
    };
    if (state.user.permissions) {
      permissions.createEditDriver = state.user.permissions.createEditDriver;
      permissions.createEditVehicle = state.user.permissions.createEditVehicle;
      permissions.createEditRouteFile = state.user.permissions.createEditRouteFile;
      permissions.createEditOlderLeaveRequests = state.user.permissions.createEditOlderLeaveRequests;
    }
    return permissions;
  },
  isAdmin(state) {
    if (!state.isAuthenticated) {
      return false;
    }

    return state.user.type === 'admin' || state.user.type === 'super-admin';
  },
};

const actions = {
  [VALIDATE_ROMFOUR_SESSION](context, payload) {
    let loginRoute = '/auth/user/session-login';

    return new Promise((resolve, reject) => {
      ApiService.post(loginRoute, payload)
        .then((response) => {
          context.commit(SET_AUTH, response.data.data);
          setTimeout(() => {
            resolve(response.data);
          }, 500);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

const mutations = {
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data;
    AuthService.saveUser(data);
    AuthService.saveToken(data.accessToken);
  },
  [PURGE_AUTH](state) {
    AuthService.destroyToken();
    AuthService.destroyUser();
    state.isAuthenticated = false;
    state.user = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
