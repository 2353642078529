import {
  SIDEBAR_TOGGLE,
  SIDEBAR_CLOSE,
  IS_LOADING,
  FINNISHED_LOADING,
} from './actions.type';
import {
  SIDEBAR_TOGGLE_M,
  SIDEBAR_CLOSE_M,
  IS_LOADING_M,
  FINNISHED_LOADING_M,
} from './mutations.type';

export default {
  state: {
    isSidebarVisible: false,
    isLoading: false,
  },
  getters: {
    isSidebarVisible(state) {
      return state.isSidebarVisible;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    [SIDEBAR_TOGGLE_M](state) {
      state.isSidebarVisible = !state.isSidebarVisible;
    },
    [SIDEBAR_CLOSE_M](state) {
      state.isSidebarVisible = false;
    },
    [IS_LOADING_M](state) {
      state.isLoading = true;
    },
    [FINNISHED_LOADING_M](state) {
      state.isLoading = false;
    },
  },
  actions: {
    [SIDEBAR_TOGGLE](context) {
      context.commit(SIDEBAR_TOGGLE_M);
    },
    [SIDEBAR_CLOSE](context) {
      context.commit(SIDEBAR_CLOSE_M);
    },
    [IS_LOADING](context) {
      context.commit(IS_LOADING_M);
    },
    [FINNISHED_LOADING](context) {
      context.commit(FINNISHED_LOADING_M);
    },
  },
};
