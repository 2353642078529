/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import CompanyService from '@/services/company.service';

import {
  FETCH_COMPANY_BRANCHES,
  FETCH_COMPANY_DEPARTMENTS,
  FETCH_COMPANY_CASH_REGISTERS,
} from './actions.type';

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    [FETCH_COMPANY_BRANCHES](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };

        if (filters && filters.denumire && filters.denumire != '') {
          query.denumire = ['like', filters.denumire];
        }
        if (filters && filters.codSoc && filters.codSoc != '') {
          query.codSoc = ['=', filters.codSoc];
        }

        // do the actual API call
        CompanyService.queryBranches(query)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_COMPANY_DEPARTMENTS](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };
        if (filters && filters.numeSectie && filters.numeSectie != '') {
          query.numeSectie = ['like', filters.numeSectie];
        }
        if (filters && filters.codSoc && filters.codSoc != '') {
          query.codSoc = ['=', filters.codSoc];
        }

        if (filters && filters.codPunct && filters.codPunct != '') {
          query.codPunct = ['=', filters.codPunct];
        }


        // do the actual API call
        CompanyService.queryDepartments(query)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
    [FETCH_COMPANY_CASH_REGISTERS](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };
        if (filters && filters.numeCasaMarcat && filters.numeCasaMarcat != '') {
          query.numeCasaMarcat = ['like', filters.numeCasaMarcat];
        }
        if (filters && filters.codSoc && filters.codSoc != '') {
          query.codSoc = ['=', filters.codSoc];
        }

        // do the actual API call
        CompanyService.queryCashRegisters(query)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
  },
};
