/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import DriversService from '@/services/drivers.service';

import {
  FETCH_DRIVERS,
  FETCH_DRIVER,
  DRIVER_CREATE_SUBMIT,
  DRIVER_EDIT_SUBMIT,
  DRIVER_INIT_NEW_SERIES,
  DRIVER_UPLOAD_DOCUMENT,
} from '../actions.type';

import {
  FETCH_DRIVERS_END,
  FETCH_DRIVER_END,
  UPDATE_FILTER_DRIVERS_M,
  DRIVER_INIT_NEW_FORM
} from '../mutations.type';

import moment from 'moment';


export default {
  state: {
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    newDriverForm: {},
    editDriverForm: {},
    driverData:[],
  },
  getters: {
    driversItems(state) {
      return state.data;
    },
    driversPerPage(state) {
      return state.perPage;
    },
    driversCurrentPage(state) {
      return state.currentPage;
    },
    driversLastPage(state) {
      return state.numPages;
    },
    driversNextPage(state) {
      return state.nextPage;
    },
    driversPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    driversFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    driversTo(state) {
      return state.perPage * state.currentPage;
    },
    driversTotal(state) {
      return state.total;
    },
    newDriverForm(state) {
      return state.newDriverForm;
    },
    editDriverForm(state) {
      return state.editDriverForm;
    },
    driverData(state) {
      return state.driverData;
    },
  },
  setters: {
    initNewDriverForm(state) {
      state.newDriverForm = {};
    },
    driversCurrentPage(state, value) {
      state.currentPage = value;
    }
  },
  mutations: {
    [FETCH_DRIVERS_END](state, response) {
      response.data.forEach((driver) => {
        driver.daysAtHome = driver.lastArrivalTime ? moment().diff(moment(driver.lastArrivalTime), 'days') : '-';
      });

      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
    [FETCH_DRIVER_END](state, response) {
      state.driver = response.data;
    },

    [UPDATE_FILTER_DRIVERS_M](state, data) {
      Vue.set(state.filters, data);
    },
  },
  actions: {
    [FETCH_DRIVERS](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters, sort } = params;
        let query = { page, perPage };

        if (filters && filters.driverStatus && filters.driverStatus != '') {
          query.driverStatus = ['=', filters.driverStatus];
        }

        if (filters && filters.ciExpirationDate && filters.ciExpirationDate != '') {
          query.ciExpirationDate = ['>=', moment(filters.ciExpirationDate.toISOString()).format('DD/MM/YYYY')];
        }

        if (filters && filters.dlExpirationDate && filters.dlExpirationDate != '') {
          query.dlExpirationDate = ['>=', moment(filters.dlExpirationDate.toISOString()).format('DD/MM/YYYY')];
        }

        if (filters && filters.psiExpirationDate && filters.psiExpirationDate != '') {
          query.psiExpirationDate = ['>=', moment(filters.psiExpirationDate.toISOString()).format('DD/MM/YYYY')];
        }

        if (filters && filters.cardTahExpirationDate && filters.cardTahExpirationDate != '') {
          query.cardTahExpirationDate = ['>=', moment(filters.cardTahExpirationDate.toISOString()).format('DD/MM/YYYY')];
        }

        if (filters && filters.medExpirationDate && filters.medExpirationDate != '') {
          query.medExpirationDate = ['>=', moment(filters.medExpirationDate.toISOString()).format('DD/MM/YYYY')];
        }

        if (filters && filters.name && filters.name != '') {
          query.name = ['like', filters.name];
          if(filters.type) {
            query.type = ['=', filters.type];
          }
        }

        if (filters && filters.withUserStatus != undefined) {
          query.withUserStatus = ['=', filters.withUserStatus];
        }
        if (filters && filters.employmentStatus != undefined) {
          query.employmentStatus = ['=', filters.employmentStatus];
        }

        if (sort && sort.column) {
          query.sortBy = sort.column;
          query.sortDir = sort.dir;
        }

        // do the actual API call
        DriversService.query(query)
          .then((response) => {
            context.commit(FETCH_DRIVERS_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },

    [FETCH_DRIVER](context, slug) {
      return new Promise((resolve, reject) => {
        DriversService.get(slug)
          .then((response) => {
            context.commit(FETCH_DRIVER_END, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DRIVER_INIT_NEW_SERIES](context) {
      return new Promise((resolve, reject) => {
        DriversService.newDriverSeries()
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DRIVER_CREATE_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        DriversService.create(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DRIVER_EDIT_SUBMIT](context, data) {
      return new Promise((resolve, reject) => {
        DriversService.update(data.id, data.formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DRIVER_UPLOAD_DOCUMENT](context, data) {
      return new Promise((resolve, reject) => {
        DriversService.uploadDocument(data.id, data.formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [DRIVER_INIT_NEW_FORM](state) {
      state.newDriverForm = {};
    },
  },
};
