/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/regions', { params });
  },
  get(params) {
    return ApiService.get(`/regions?${params}`);
  },

};
