export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const FORGOT_USERNAME = 'forgotUsername';
export const FORGOT_PASSWORD = 'forgotPassword';
export const RESET_PASSWORD = 'resetPassword';

export const SIDEBAR_TOGGLE = 'toggleSidebar';
export const SIDEBAR_CLOSE = 'closeSidebar';

export const IS_LOADING = 'isLoading';
export const FINNISHED_LOADING = 'finnishedLoading';

export const FETCH_PAGE = 'fetchPage';

export const FETCH_DRIVERS = 'fetchDrivers';
export const FETCH_DRIVER = 'fetchDriver';
export const UPDATE_ACTIVE_SORT_FIELD_DRIVERS = 'updateActiveSortFieldDrivers';
export const UPDATE_SEARCH_DRIVERS = 'updateSearchDrivers';
export const CLEAR_SEARCH_DRIVERS = 'clearSearchDrivers';
export const DRIVER_CREATE_SUBMIT = 'createNewDriver';
export const DRIVER_EDIT_SUBMIT = 'editDriver';
export const DRIVER_UPLOAD_DOCUMENT = 'uploadDriverDocument';
export const DRIVER_INIT_NEW_SERIES = 'createNewDriverSeries';

export const FETCH_ROUTE_FILES = 'fetchRouteFiles';
export const FETCH_ROUTE_STATIONS = 'getPredefinedStations';
export const UPDATE_ACTIVE_SORT_FIELD_ROUTE_FILES = 'updateActiveSortFieldRouteFiles';
export const UPDATE_SEARCH_ROUTE_FILES = 'updateSearchRouteFiles';
export const CLEAR_SEARCH_ROUTE_FILES = 'clearSearchRouteFiles';
export const CLOSE_ROUTE_FILE = 'routeFilesClose';
export const ROUTE_FILES_CREATE_SUMBIT = 'routeFilesCreateSubmit';
export const ROUTE_FILES_EDIT_SUMBIT = 'routeFilesEditSubmit';
export const ROUTE_FILES_INIT_EDIT_DATA = 'routeFilesInitEditData';
export const ROUTE_FILES_LOGBOOKS_CREATE_SUMBIT = 'routeFilesLogbooksCreateSubmit';
export const ROUTE_FILES_LOGBOOKS_EDIT_SUMBIT = 'routeFilesLogbooksEditSubmit';
export const ROUTE_FILES_LOGS_INIT_EDIT_DATA = 'routeFilesLogbooksEditInitData';
export const UPDATE_FILTER_ROUTE_FILES = ''

export const FETCH_VEHICLES = 'fetchVehicles';
export const FETCH_VEHICLES_FOR_EXTERNAL = 'fetchVehiclesForExternal';
export const FETCH_VEHICLE = 'fetchVehicle';
export const FETCH_VEHICLES_TYPES = 'fetchVehiclesTypes';
export const FETCH_VEHICLES_CLASSES = 'fetchVehiclesClasses';
export const UPDATE_FILTER_VEHICLES = 'updateFilterVehicles';
export const VEHICLE_EDIT_SUBMIT = 'editVehicle';

export const PAGE_CHANGE_FIELD_VALUE = ''
export const PAGE_DELETE_SUBMIT = ''
export const PAGE_CREATE_SUMBIT = ''
export const PAGE_EDIT_SUMBIT = ''

export const FETCH_REGIONS = 'fetchRegions';

export const FETCH_EMPLOYEES = 'fetchEmployees';
export const FETCH_EMPLOYEE = 'fetchEmployee';
export const FETCH_EMPLOYEE_LEAVE_REQUESTS = 'fetchEmployeeLeaveRequests';
export const EMPLOYEE_LEAVE_REQUESTS_CREATE_SUMBIT = 'employeeLeaveRequestsCreateSubmit';
export const EMPLOYEE_LEAVE_REQUESTS_EDIT_SUBMIT = 'employeeLeaveRequestsEditSubmit';
export const EMPLOYEE_LEAVE_REQUESTS_DELETE = 'employeeLeaveRequestsDelete';

export const VALIDATE_ROMFOUR_SESSION = 'validateUserBySessionId';


export const FETCH_REPORT = 'fetchReport';

export const FETCH_USERS = 'fetchUsers';

export const FETCH_COMPANY_BRANCHES = 'fetchCompanyBranches';

export const FETCH_COMPANY_DEPARTMENTS = 'fetchCompanyDepartments';

export const FETCH_COMPANY_CASH_REGISTERS = 'fetchCompanyCashRegisters';

//Hub actions =======================
export const FETCH_HUBS = 'fetchHubs';
export const FETCH_HUB = 'fetchHub';
export const UPDATE_SEARCH_HUBS = 'updateSearchHubs';
export const CLEAR_SEARCH_HUBS = 'clearSearchHubs';
export const HUB_CREATE_SUBMIT = 'createNewHub';
export const HUB_EDIT_SUBMIT = 'editHub';
export const HUB_DELETE_SUBMIT = 'deleteHub';
export const FETCH_HUB_ROUTES = 'fetchHubRoutes';

//Relation between Vehicle and Hub actions =======================
export const FETCH_VEHICLESCOURSETYPE = 'fetchVehiclesCourseType';
export const FETCH_VEHICLECOURSETYPE = 'fetchVehicleCourseType';
export const UPDATE_SEARCH_VEHICLESCOURSETYPE = 'updateFilterVehicles';
export const VEHICLE_COURSE_TYPE_EDIT_SUBMIT = 'editVehicleCourseType';
export const VEHICLE_COURSE_TYPE_CREATE_SUBMIT = 'createNewVehicleCourseType';
export const VEHICLE_COURSE_TYPE_DELETE = 'deleteVehicleCourseType';
export const GET_COURSE_TYPE_RELATION = "getCourseTypeRelation";
export const VEHICLE_COURSE_TYPE_UPDATE_TRANSPORTYPE = "updateTransportType";


//Vehicle Situation (Vehicle Status)========================
export const FETCH_VEHICLES_STATUS = 'fetchVehiclesStatus';
export const FETCH_VEHICLE_STATUS = 'fetchVehicleStatus';
export const UPDATE_VEHICLE_STATUS = 'updateVehicleStatus';
export const DELETE_VEHICLE_STATUS = 'deleteVehicleStatus';
export const ADD_VEHICLE_STATUS = 'addVehicleStatus';
export const UPDATE_FILTER_VEHICLES_STATUS = 'updateFilterVehicleStatus';

//Route Definitions ==============================================
export const FETCH_TIPURI_RUTE = 'fetchTipuriRute';
export const FETCH_LOCALITIES = 'fetchLocalities';
export const FETCH_ROUTEDEFINITIONS = 'fetchRouteDefinitions';
export const FETCH_ROUTEDEFINITION = 'fetchRouteDefinition';
export const ROUTEDEFINITION_CREATE_SUBMIT = 'createNewRouteDefinition';
export const ROUTEDEFINITION_EDIT_SUBMIT = 'editRouteDefinition';
export const ROUTEDEFINITION_DELETE_SUBMIT = 'deleteRouteDefinition';


//Reservations ==============================================
export const FETCH_RESERVATIONS = 'fetchReservations';
export const FETCH_RESERVATIONS_CRUISE = 'fetchReservationsCruise';
export const FETCH_RESERVATION = 'fetchReservation';
export const UPDATE_FILTER_RESERVATIONS = 'updateFilterReservations';


//Cruise Planning
export const FETCH_CRUISES = 'fetchcruises';
export const FETCH_CRUISE = 'fetchcruise';
export const CRUISE_CREATE_SUBMIT = 'createNewcruise';
export const CRUISE_DELETE_SUBMIT = 'deleteNewcruise';
export const CRUISE_EDIT_SUBMIT = 'editNewcruise';
export const CRUISE_INIT_NEW_FORM = 'newFormNewcruise';
export const CRUISE_STATUS_SUBMIT = 'cruiseStatusSubmit';

export const FETCH_SAVED_RESERVATIONS = 'fetchSavedReservations';
export const FETCH_SAVED_RESERVATIONS_WITH_ID = 'fetchSavedReservationsWithId';
export const FETCH_CRUISES_WITH_VEHICLES_AND_DRIVERS = 'fetchCruiseVehiclesDrivers';
export const FETCH_RESERVATION_VEHICLE_NAME = 'fetchReservationVehicleName';
export const CRUISE_DELETE = 'cruiseDelete';

//Cruise Reservations
export const CRUISE_RESERVATIONS_CREATE_SUBMIT = 'createNewcruiseReservations';
export const CRUISE_RESERVATIONS_MAIN_VEHICLE_SUBMIT = 'mainVehicleSubmit';
export const CRUISE_RESERVATIONS_EDIT = 'cruiseReservationsEdit';
export const CRUISE_RESERVATIONS_TRANSFER_VEHICLE_SUBMIT = 'transferVehicleSubmit';
export const CRUISE_RESERVATIONS_DELETE_SUBMIT = 'deleteCruiseReservationsSubmit';
export const FETCH_CRUISE_RESERVATIONS_VEHICLES = 'fetchCruiseReservationsVehicles';
export const CRUISE_RESERVATIONS_VEHICLES_CREATE = 'cruiseReservationsVehiclesCreate';
export const CRUISE_RESERVATIONS_VEHICLES_DELETE = 'cruiseReservationsVehiclesDelete';
export const FETHC_CRUISE_RESERVATIONS_VEHICLE_DRIVERS = 'fetchCruiseReservationsVehicleDrivers';
export const DELETE_CRUISE_RESERVATIONS_VEHICLES_DRIVERS = 'deleteCruiseReservationsVehiclesDrivers';
//Rides
export const FETCH_RIDES = 'fetchRides';
