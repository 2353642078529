/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import HubsService from '@/services/hubs.service';

import {
  FETCH_HUBS,
  FETCH_HUB,
  UPDATE_SEARCH_HUBS,
  HUB_CREATE_SUBMIT,
  FETCH_HUB_ROUTES,
  HUB_EDIT_SUBMIT, HUB_DELETE_SUBMIT,
} from '../actions.type';

import {
  FETCH_HUBS_END,
  FETCH_HUB_END,
  UPDATE_FILTER_HUBS_M,
  HUB_INIT_NEW_FORM, FETCH_HUB_ROUTES_END
} from '../mutations.type';

import moment from 'moment';
import EmployeesService from "@/services/employees.service";


export default {
  state: {
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    newHubForm: {},
    editHubForm: {},
    deleteHubForm: {},
    hubData:[],
  },
  getters: {
    hubsItems(state) {
      return state.data;
    },
    hubsPerPage(state) {
      return state.perPage;
    },
    hubsCurrentPage(state) {
      return state.currentPage;
    },
    hubsLastPage(state) {
      return state.numPages;
    },
    hubsNextPage(state) {
      return state.nextPage;
    },
    hubsPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    hubsFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    hubsTo(state) {
      return state.perPage * state.currentPage;
    },
    hubsTotal(state) {
      return state.total;
    },
    newHubForm(state) {
      return state.newHubForm;
    },
    editHubForm(state) {
      return state.editHubForm;
    },
    hubData(state) {
      return state.hubData;
    },
  },
  setters: {
    initNewHubForm(state) {
      state.newHubForm = {};
    },
    hubsCurrentPage(state, value) {
      state.currentPage = value;
    }
  },
  mutations: {
    [FETCH_HUBS_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
    [FETCH_HUB_END](state, response) {
      state.hub = response.data;
    },

    [UPDATE_FILTER_HUBS_M](state, data) {
      Vue.set(state.filters, data);
    },
  },
  actions: {
    [FETCH_HUBS](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters, sort } = params;
        let query = { page, perPage };

        if (filters && filters.name && filters.name != '') {
          query.name = ['like', filters.name];
        }
        if (filters && filters.country && filters.country != '') {
          query.country = ['like', filters.country];
        }
        if (filters && filters.type && filters.type != '') {
          query.type = ['=', filters.type];
        }
        if (sort && sort.column) {
          query.sortBy = sort.column;
          query.sortDir = sort.dir;
        }
        // do the actual API call
        HubsService.query(query)
          .then((response) => {
            context.commit(FETCH_HUBS_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_HUB_ROUTES](context) {
      return new Promise((resolve, reject) => {
        HubsService.getHubRoutes()
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },

    [FETCH_HUB](context, slug) {
      return new Promise((resolve, reject) => {
        HubsService.get(slug)
          .then((response) => {
            context.commit(FETCH_HUB_END, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [HUB_CREATE_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        HubsService.create(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [HUB_EDIT_SUBMIT](context, data) {
      return new Promise((resolve, reject) => {
        HubsService.update(data.id, data.formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [HUB_DELETE_SUBMIT](context, hubId) {
      return new Promise((resolve, reject) => {
        HubsService.destroy(hubId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [HUB_INIT_NEW_FORM](state) {
      state.newHubForm = {};
    },
  },
};
