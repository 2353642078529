/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/reservations', { params });
  },
  getReservations(params) {
    return ApiService.query('/getReservations', { params });
  },
  get(id) {
    return ApiService.get('/reservation', id);
  },
  create(data) {
    return ApiService.post('/reservation', data);
  },
  update(id, data) {
    return ApiService.post(`/reservation/${id}`, data);
  },
  destroy(id) {
    return ApiService.delete(`/reservation/${id}`);
  },
};
