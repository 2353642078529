/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import ridesService from '@/services/rides.service';

import {
  FETCH_RIDES,
} from '../actions.type';

import {
  FETCH_RIDE_END,
  FETCH_RIDES_END, UPDATE_FILTER_RIDES_M
} from '../mutations.type';
import moment from "moment";

export default {
  state: {
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    ridesData:[],
  },
  getters: {
    ridesItems(state) {
      return state.data;
    },
    ridesPerPage(state) {
      return state.perPage;
    },
    ridesCurrentPage(state) {
      return state.currentPage;
    },
    ridesLastPage(state) {
      return state.numPages;
    },
    ridesNextPage(state) {
      return state.nextPage;
    },
    ridesPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    ridesFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    ridesTo(state) {
      return state.perPage * state.currentPage;
    },
    ridesTotal(state) {
      return state.total;
    },
    rideData(state) {
      return state.rideData;
    },
  },
  setters: {
    ridesCurrentPage(state, value) {
      state.currentPage = value;
    }
  },
  mutations: {
    [FETCH_RIDES_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
    [FETCH_RIDE_END](state, response) {
      state.ride = response.data;
    },

    [UPDATE_FILTER_RIDES_M](state, data) {
      Vue.set(state.filters, data);
    },
  },
  actions: {
    [FETCH_RIDES](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters, sort } = params;
        let query = { page, perPage };
        if (filters && filters.codCursa && filters.codCursa != '') {
          query.codCursa = ['like', filters.codCursa];
        }
        if (filters && filters.data && filters.data != '') {
          query.data = ['>=', moment(filters.data.toISOString()).format('YYYY-MM-DD')];
        }
        if (filters && filters.codIdentificare && filters.codIdentificare != '') {
          query.codIdentificare = ['like', filters.codIdentificare];
        }
        if (filters && filters.numeRuta && filters.numeRuta != '') {
          query.numeRuta = ['like', filters.numeRuta];
        }
        if (sort && sort.column) {
          query.sortBy = sort.column;
          query.sortDir = sort.dir;
        }
        // do the actual API call
        ridesService.query(query)
          .then((response) => {
            context.commit(FETCH_RIDES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    // [FETCH_HUB](context, slug) {
    //   return new Promise((resolve, reject) => {
    //     ridesService.get(slug)
    //       .then((response) => {
    //         context.commit(FETCH_HUB_END, response.data.data);
    //         resolve(response.data);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
  },
};
