import Vue from 'vue';
import ReportsService from '@/services/reports.service';

import {
  FETCH_REPORT,
} from './actions.type';

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    [FETCH_REPORT](context, params) {
      return new Promise((resolve, reject) => {
        // const { page, perPage, filters } = params;
        // let query = { page, perPage };
        // do the actual API call
        return ReportsService.downloadReport(params.reportName)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
           Vue.notify({
             group: 'errors',
             type: 'error',
             title: error,
           })
            reject(error);
          });
      });
    },
  },
};
