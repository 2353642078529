/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import layoutModule from '@/store/layout';
import authModule from '@/store/auth';
import driversModule from '@/store/drivers/drivers';
import hubsModule from '@/store/hubs/hubs';
import routeFilesModule from "@/store/routes/routeFiles";
import vehiclesModule from "@/store/vehicles/vehicles";
import employeesModule from "@/store/drivers/employees";
import usersModule from "@/store/user";
import companyModule from "@/store/company";
import reportsModule from "@/store/reports";
import vehiclesCourseTypeModule from "@/store/vehiclesCourseType/vehiclesCourseType";
import vehiclesStatusModule from "@/store/vehiclesStatus/vehicleStatus";
import routeDefinitionModule from "@/store/routeDefinitions/routeDefinitions";
import reservationsModule from "@/store/reservations/reservations";
import cruisesModule from "@/store/cruises/cruises";
import ridesModule from "@/store/rides/rides";
import FlashMessage from '@smartweb/vue-flash-message';
import VueI18n from 'vue-i18n'
import cruiseReservationsModule from "@/store/cruiseReservations/cruiseReservations";
Vue.use(VueI18n);
Vue.use(FlashMessage);
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout: layoutModule,
    auth: authModule,
    drivers: driversModule,
    hubs: hubsModule,
    routeFiles: routeFilesModule,
    vehicles: vehiclesModule,
    employees: employeesModule,
    users: usersModule,
    company: companyModule,
    reports: reportsModule,
    vehiclesCourseType: vehiclesCourseTypeModule,
    vehiclesStatus: vehiclesStatusModule,
    routeDefinitions: routeDefinitionModule,
    reservations: reservationsModule,
    cruises: cruisesModule,
    cruiseReservations: cruiseReservationsModule,
    rides: ridesModule,
  },
});
