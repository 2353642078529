/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import Reservations from '@/services/reservations.service';

import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATION,
  UPDATE_FILTER_RESERVATIONS, FETCH_RESERVATIONS_CRUISE,

} from '../actions.type';

import {
  FETCH_RESERVATIONS_END, FETCH_RESERVATION_END, UPDATE_FILTER_RESERVATIONS_M, FETCH_RESERVATIONS_CRUISE_END
} from '../mutations.type';
import {isNullOrUndefined} from "@tinymce/tinymce-vue/lib/cjs/main/ts/Utils";
import moment from 'moment';

export default {
  state: {
    filters: {
      id: null,
      codSoc: null,
      nume: null,
      punctPlecare: null,
      punctSosire: null,
      dataCursa: null,
    },
    data: [],
    numPages: 0,
    perPage: 20,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 2,
    total: 2,
    sort: [],

  },
  getters: {
    reservationsItems(state) {
      return state.data;
    },
    reservationsPerPage(state) {
      return state.perPage;
    },
    reservationsCurrentPage(state) {
      return state.currentPage;
    },
    reservationsLastPage(state) {
      return state.numPages;
    },
    reservationsNextPage(state) {
      return state.nextPage;
    },
    reservationsPreviousPage(state) {
      return state.currentPage ? state.currentPage - 1 : 0;
    },
    reservationsFrom(state) {
      return state.perPage * (state.currentPage - 1) + 1;
    },
    reservationsTo(state) {
      return state.perPage * state.currentPage;
    },
    reservationsTotal(state) {
      return state.total;
    },
    reservationsFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    [FETCH_RESERVATIONS_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;

    },
  },
  actions: {
    [FETCH_RESERVATIONS](context, params) {
      return new Promise((resolve, reject) => {
        const {page, perPage, filters} = params;
        filters.dataCursa = new Date();
        let query = {page, perPage};

        if (filters && filters.id && filters.id != '') {
          query.id = ['=', filters.id];
        }
        if (filters && filters.id && filters.id != '') {
          query.id = ['=', filters.id];
        }
        if (filters && filters.nume && filters.nume != '') {
          query.nume = ['like', filters.nume];
        }
        if (filters && filters.codeSoc && filters.codeSoc != '') {
          query.codeSoc = ['like', filters.codeSoc];
        }
        if (filters && filters.numeRuta && filters.numeRuta != '') {
          query.numeRuta = ['like', filters.numeRuta];
        }
        if (filters && filters.bilet && filters.bilet != '') {
          query.bilet = ['like', filters.bilet];
        }
        if (filters && filters.numePunct && filters.numePunct != '') {
          query.numePunct = ['like', filters.numePunct];
        }
        if (filters && filters.punctSosire && filters.punctSosire != '') {
          query.punctSosire = ['like', filters.punctSosire];
        }
        if (filters && filters.punctPlecare && filters.punctPlecare != '') {
          query.punctPlecare = ['like', filters.punctPlecare];
        }
        if (filters && filters.numeJurnal && filters.numeJurnal != '') {
          query.numeJurnal = ['like', filters.numeJurnal];
        }
        if (filters && filters.dataCursa && filters.dataCursa != '') {
          query.dataCursa = ['d>=', moment(filters.dataCursa.toISOString()).format('DD/MM/YYYY')];
        }

        // do the actual API call
        Reservations.query(query)
          .then((response) => {
            context.commit(FETCH_RESERVATIONS_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
          });
      });
    },
    [FETCH_RESERVATION](context, slug) {
      return new Promise((resolve, reject) => {
        Reservations.get(slug)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_RESERVATIONS_CRUISE](context, params) {
      return new Promise((resolve, reject) => {
        Reservations.getReservations(params)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
          });
      });
    },
    [UPDATE_FILTER_RESERVATIONS](context, params) {
      context.commit(UPDATE_FILTER_RESERVATIONS_M, params);
    },
  },
};
