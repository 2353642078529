<template>
  <div id="aside" class="page-sidenav no-shrink bg-light nav-dropdown fade" aria-hidden="true" @v-if="isAuthenticated()">
    <div class="sidenav h-100 modal-dialog bg-light">
      <!-- sidenav top -->
      <!-- Flex nav content -->
      <div class="flex scrollable hover">
        <div
          class="nav-stacked nav-active-primary auto-nav"
          v-show="!$route.path.includes('/vehicles-folders')"
          data-nav
        >
          <ul class="nav">
            <li class="nav-header">
              <span class="text-xs hidden-folded">Dosar Soferi</span>
            </li>
            <li>
              <a @click="gotoRoute('drivers')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Administrare Soferi</span>
                <span class="nav-caret">
                      </span>
              </a>
            </li>
            <li>
              <a @click="gotoRoute('vehiclesList')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Autovehicule</span>
                <span class="nav-caret"></span>
              </a>
            </li>
            <li>
              <a @click="gotoRoute('routeFiles')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Alocare Dosare</span>
                <span class="nav-caret">
                      </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="nav-stacked nav-active-primary auto-nav"
           v-show="$route.path.includes('/vehicles-folders')"
           data-nav
        >
          <ul class="nav">
            <li class="nav-header">
              <span class="text-xs hidden-folded">Dosar Rute Si Situatie Vehicule</span>
            </li>
            <li>
              <a @click="gotoRoute('hubs')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Puncte Transfer / Hub-uri</span>
                <span class="nav-caret"></span>
              </a>
            </li>
            <li>
              <a @click="gotoRoute('vehiclesCourseType')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Autovehicule</span>
                <span class="nav-caret"></span>
              </a>
            </li>
            <li>
              <a @click="gotoRoute('vehicleStatus')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Situatie Autovehicule</span>
                <span class="nav-caret"></span>
              </a>
            </li>
            <li>
              <a @click="gotoRoute('routeDefinitions')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Tipuri Rute</span>
                <span class="nav-caret"></span>
              </a>
            </li>
            <li>
              <a>
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Curse</span>
                <span class="nav-caret nav-caret-down"></span>
              </a>
              <ul style="margin-left: -30px;">
                <li style="max-height: 30px;">
                  <a @click="gotoRoute('cruiseList')">
                    <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                    <span class="nav-text" style="padding:0;">Lista Curse</span>
                    <span class="nav-caret"></span>
                  </a>
                </li>
                <li style="max-height: 30px;">
                  <a @click="gotoRoute('cruisePlanningList')">
                    <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                    <span class="nav-text" style="padding:0;">Planificare Curse</span>
                    <span class="nav-caret"></span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a @click="gotoRoute('reservations')">
                <span class="nav-icon"><i data-feather="shopping-bag"></i></span>
                <span class="nav-text">Rezervari</span>
                <span class="nav-caret"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- sidenav bottom -->
      <div class="py-2 mt-2 b-t no-shrink">
        <ul class="nav no-border">
          <li>
            <a href="#">
                <span class="nav-icon">
                  <i data-feather="power"></i>
                </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BNavbar, BNavbarBrand, BNavbarToggle, BNavItem, BNavItemDropdown, BDropdownItem,
  BNavbarNav, BCollapse
} from "bootstrap-vue";
import { SIDEBAR_TOGGLE } from '@/store/actions.type';

export default {
  name: 'RomSidebar',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BNavbarNav,
    BCollapse,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch(SIDEBAR_TOGGLE);
    },
    gotoRoute(routeName) {
      this.$router.push({name: routeName});
    },
    home() {
      this.$router.push('/');
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.user;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>
