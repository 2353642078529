import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'ro',
  fallbackLocale: 'ro',
  messages: {
    ro: {
      transfer: 'Disponibil pentru transfer',
      course: 'Disponibil pentru curse',
      transferAndCourse: 'Disponibil pentru transfer & curse',
      damaged: 'Indisponibil: Masina avariata',
      broken: 'Indisponibil: Masina defecta',
      withoutDriver: 'Indisponibil: Masina fara sofer',
      withoutDocuments: 'Indisponibil: Masina fara acte',
      service: 'Indisponibil: Masina in service',
      ontrack: 'Indisponibil: Masina in Cursa',
      unavailable: 'Indisponibil',
      cars: {
        damaged: 'Masina avariata',
        broken: 'Masina defecta',
        withoutDriver: 'Masina fara sofer',
        withoutDocuments: 'Masina fara acte',
        service: 'Masina in service',
        ontrack: 'Masina in cursa',
      }
    },
  },
});

export default i18n;
