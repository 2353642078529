/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/vehicles-status', { params });
  },
  get(vehicleId) {
    return ApiService.get('/vehicles-status', vehicleId);
  },
  create(id, data) {
    return ApiService.post(`/vehicles-status/${id}`, data);
  },
  update(id, data) {
    return ApiService.put(`/vehicles-status/${id}`, data);
  },
  delete(id, courseTypeId, data) {
    return ApiService.delete(`/vehicles-status/${id}/${courseTypeId}`, data);
  },
};
