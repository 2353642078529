/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/hubs', { params });
  },
  get(id) {
    return ApiService.get('/hub', id);
  },
  getHubs() {
    return ApiService.get('/hubs', { page: 0, size: 1000 });
  },
  create(data) {
    return ApiService.post('/hub', data);
  },
  update(id, data) {
    return ApiService.post(`/hub/${id}`, data);
  },
  destroy(id) {
    return ApiService.delete(`/hub/${id}`);
  },
  getHubRoutes() {
    return ApiService.query('/hub-routes');
  }
};
