/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import EmployeesService from '@/services/employees.service';

import {
  FETCH_EMPLOYEE_LEAVE_REQUESTS,
  EMPLOYEE_LEAVE_REQUESTS_CREATE_SUMBIT,
  EMPLOYEE_LEAVE_REQUESTS_EDIT_SUBMIT,
  EMPLOYEE_LEAVE_REQUESTS_DELETE,
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEE,
} from '../actions.type';

import {
  FETCH_EMPLOYEE_LEAVE_REQUESTS_END,
  FETCH_EMPLOYEE_END,
} from '../mutations.type';

export default {
  state: {
    employeeLeaveRequests: [],
    employee: {},
  },
  getters: {
    employeeLeaveRequestsItems(state) {
      return state.employeeLeaveRequests;
    },
    employee(state) {
      return state.employee;
    },
    employeeLeaveRequestsTotal(state) {
      return state.employeeLeaveRequests.length;
    },
  },

  mutations: {
    [FETCH_EMPLOYEE_LEAVE_REQUESTS_END](state, response) {
      state.employeeLeaveRequests = response.data;
    },
    [FETCH_EMPLOYEE_END](state, response) {
      state.employee = response.data;
    },
  },

  actions: {
    [FETCH_EMPLOYEE_LEAVE_REQUESTS](context, employeeId) {
      return new Promise((resolve, reject) => {
        // do the actual API call
        EmployeesService.queryLeaveRequests(employeeId, {})
          .then((response) => {
            context.commit(FETCH_EMPLOYEE_LEAVE_REQUESTS_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },

    [EMPLOYEE_LEAVE_REQUESTS_CREATE_SUMBIT](context, formData) {
      return new Promise((resolve, reject) => {
        EmployeesService.createLeaveRequests(formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [EMPLOYEE_LEAVE_REQUESTS_EDIT_SUBMIT](context, formData) {
      return new Promise((resolve, reject) => {
        EmployeesService.editLeaveRequests(formData.id, formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [EMPLOYEE_LEAVE_REQUESTS_DELETE](context, leaveRequestId) {
      return new Promise((resolve, reject) => {
        EmployeesService.deleteLeaveRequests(leaveRequestId)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_EMPLOYEES](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };

        if (filters && filters.name && filters.name != '') {
          query.prenume = ['like', filters.name];
          query.nume = ['like', filters.name];
        }

        // do the actual API call
        EmployeesService.query(query)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
    [FETCH_EMPLOYEE](context, employeeId) {
      return new Promise((resolve, reject) => {
        EmployeesService.get(employeeId, {})
          .then((response) => {
            context.commit(FETCH_EMPLOYEE_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            });
            reject(error);
          });
      });
    },
  },
};
