/* eslint-disable import/no-cycle */
import ApiService from '@/services/api.service';

export default {
  query(params) {
    return ApiService.query('/route-definitions', { params });
  },
  get(id) {
    return ApiService.get('/route-definition', id);
  },
  getHubs() {
    return ApiService.get('/route-definitions', { page: 0, size: 1000 });
  },
  create(data) {
    return ApiService.post('/route-definition', data);
  },
  update(id, data) {
    return ApiService.post(`/route-definition/${id}`, data);
  },
  destroy(id) {
    return ApiService.delete(`/route-definition/${id}`);
  },
  getTipuriRute() {
    return ApiService.query('/tipuri-rute');
  },
  getLocalities(params) {
    return ApiService.query('/localities', { params });
  }
};
