/* eslint-disable import/no-cycle,no-param-reassign */
import Vue from 'vue';
import moment from 'moment';
import RouteFilesService from '@/services/routeFiles.service';

import {
  FETCH_ROUTE_FILES,
  FETCH_ROUTE_STATIONS,
  UPDATE_ACTIVE_SORT_FIELD_ROUTE_FILES,
  UPDATE_FILTER_ROUTE_FILES,
  UPDATE_SEARCH_ROUTE_FILES,
  CLEAR_SEARCH_ROUTE_FILES,
  ROUTE_FILES_EDIT_SUMBIT,
  ROUTE_FILES_INIT_EDIT_DATA,
  ROUTE_FILES_CREATE_SUMBIT, FETCH_REGIONS,
  ROUTE_FILES_LOGBOOKS_CREATE_SUMBIT,
  ROUTE_FILES_LOGBOOKS_EDIT_SUMBIT,
  ROUTE_FILES_LOGS_INIT_EDIT_DATA,
  CLOSE_ROUTE_FILE,
} from '../actions.type';

import {
  FETCH_ROUTE_FILES_END,
  UPDATE_ACTIVE_SORT_FIELD_ROUTE_FILES_M,
  UPDATE_FILTER_ROUTE_FILES_M,
  UPDATE_SEARCH_ROUTE_FILES_M,
  CLEAR_SEARCH_ROUTE_FILES_M,
  ROUTE_FILES_INIT_EDIT_DATA_M,
  ROUTE_FILES_INIT_NEW_DATA_M, FETCH_REGIONS_END,
} from '../mutations.type';
import RegionService from "../../services/regions.service";
import {isNullOrUndefined} from "@tinymce/tinymce-vue/lib/cjs/main/ts/Utils";

export default {
  state: {
    data: [],
    perPage: 20,
    currentPage: 1,
    numPages: 1,
    from: 0,
    to: 20,
    total: 0,
    sort: [],
    filters: {
      filterCategories: {
        value: [],
      },
      filterTags: {
        value: [],
      },
    },
    search: '',
  },
  getters: {
    routeFilesItems(state) {
      return state.data;
    },
    routeFilesPerPage(state) {
      return state.perPage;
    },
    routeFilesCurrentPage(state) {
      return state.currentPage;
    },
    routeFilesLastPage(state) {
      return state.numPages;
    },
    routeFilesFrom(state) {
      return state.perPage * (state.currentPage - 1) +  1;
    },
    routeFilesTo(state) {
      return state.perPage * state.currentPage;
    },
    routeFilesTotal(state) {
      return state.total;
    },
    routeFilesSortFields(state) {
      return state.sort;
    },
    routeFilesActiveSort(state) {
      const activeSorts = state.sort.filter((sort) => sort.active === true);
      return activeSorts[0];
    },
    routeFilesFilters(state) {
      return state.filters;
    },
    editRouteFileData(state) {
      return state.editRouteFileData;
    },
  },
  mutations: {
    [FETCH_ROUTE_FILES_END](state, response) {
      state.data = response.data;
      state.perPage = parseInt(response.meta.perPage, 10);
      state.currentPage = response.meta.currentPage;
      state.nextPage = response.meta.nextPage;
      state.hasPreviousPage = response.meta.hasPreviousPage;
      state.hasNextPage = response.meta.hasNextPage;
      state.haveToPaginate = response.meta.haveToPaginate;
      state.numPages = response.meta.numPages;
      state.total = response.meta.total;
    },
    [FETCH_REGIONS_END](state, data) {
      // state.data = data;
      // state.perPage = parseInt(data.perPage, 10);
      // state.currentPage = data.currentPage;
      // state.lastPage = data.lastPage;
      // state.from = data.from;
      // state.to = data.to;
      // state.total = data.total;
    },
    [UPDATE_ACTIVE_SORT_FIELD_ROUTE_FILES_M](state, data) {
      const newSortFields = [];
      state.sort.forEach((element) => {
        element.active = false;
        if (element.code === data) {
          element.active = true;
        }
        newSortFields.push(element);
      });

      Vue.set(state, 'sort', newSortFields);
    },
    [UPDATE_FILTER_ROUTE_FILES_M](state, data) {
      Vue.set(state.filters, data);
    },
    [UPDATE_SEARCH_ROUTE_FILES_M](state, data) {
      Vue.set(state, 'search', data);
    },
    [CLEAR_SEARCH_ROUTE_FILES_M](state) {
      Vue.set(state, 'search', '');
    },


    [ROUTE_FILES_INIT_NEW_DATA_M](state) {
      Vue.set(state, 'newRouteFileData',     {formData: {
        "vehicleId": null,
          "trailerId": null,
          "mainDriverId": null,
          "backupDriverId": null,
          "transferDriverId": null,
          "departureTime": null,
          "transferDriverId": null,
          "arrivalTime": null,
          "departureRegionId": null,
          "arrivalRegionId": null,
          "observations": null,
          "routeFileNumber": null
      }});
    },
    [ROUTE_FILES_INIT_EDIT_DATA_M](state, data) {
      Vue.set(state, 'editRouteFileData', data);
    },

  },
  actions: {
    [FETCH_REGIONS](context, params) {
      return new Promise((resolve, reject) => {
        // do the actual API call
        RegionService.query(params)
          .then((response) => {
            context.commit(FETCH_REGIONS_END, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
    [FETCH_ROUTE_FILES](context, params) {
      return new Promise((resolve, reject) => {
        const { page, perPage, filters } = params;
        let query = { page, perPage };
        if (filters && filters.departureTime && filters.departureTime != '') {
          query.departureTime = ['d=', moment(filters.departureTime).format('DD/MM/YYYY')];
        }
        if (filters && filters.arrivalTime && filters.arrivalTime != '') {
          query.arrivalTime = ['d=', moment(filters.arrivalTime).format('DD/MM/YYYY')];
        }
        if (filters && filters.departureRegion && filters.departureRegion != '') {
          query.departureRegionId = ['=', filters.departureRegion];
        }
        if (filters && filters.arrivalRegion && filters.arrivalRegion != '') {
          query.arrivalRegionId = ['=', filters.arrivalRegion];
        }
        if (filters && filters.routeFileNumber && filters.routeFileNumber != '') {
          query.routeFileNumber = ['like', filters.routeFileNumber];
        }

        if (filters && filters.routeFileId && filters.routeFileId != '') {
          query.routeFileId = ['like', filters.routeFileId];
        }

        if (filters && filters.selectedVehicle && filters.selectedVehicle != '') {
          query.vehicleId = ['=', filters.selectedVehicle];
        }

        if (filters && filters.routeFileStatus && filters.routeFileStatus != '') {
          if (filters.routeFileStatus === 'forced-closed') {
            query.forcedClosed = ['=', 1];
          } else {
            query.status = ['=', filters.routeFileStatus];
          }
        }

        if (filters && !isNullOrUndefined(filters.driverEmployeeId)) {
          query.driverEmployeeId = ['=', filters.driverEmployeeId];
        }

        RouteFilesService.query(query)
          .then((response) => {
            context.commit(FETCH_ROUTE_FILES_END, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
    [UPDATE_ACTIVE_SORT_FIELD_ROUTE_FILES](context, params) {
      context.commit(UPDATE_ACTIVE_SORT_FIELD_ROUTE_FILES_M, params);
    },
    [UPDATE_FILTER_ROUTE_FILES](context, params) {
      context.commit(UPDATE_FILTER_ROUTE_FILES_M, params);
    },
    [UPDATE_SEARCH_ROUTE_FILES](context, params) {
      context.commit(UPDATE_SEARCH_ROUTE_FILES_M, params);
    },
    [CLEAR_SEARCH_ROUTE_FILES](context) {
      context.commit(CLEAR_SEARCH_ROUTE_FILES_M);
    },

    [ROUTE_FILES_CREATE_SUMBIT](context, data) {
      return new Promise((resolve, reject) => {
        RouteFilesService.create(data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [CLOSE_ROUTE_FILE](context, routeFileId) {
      return new Promise((resolve, reject) => {
        RouteFilesService.close(routeFileId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ROUTE_FILES_LOGBOOKS_CREATE_SUMBIT](context, params) {
      return new Promise((resolve, reject) => {
        let logbooks = [];
        for (let logbook in params.data) {
          logbooks.push(params.data[logbook])
        }

        RouteFilesService.createLogbooks(params.routeFileId, logbooks)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
      },
    [ROUTE_FILES_LOGBOOKS_EDIT_SUMBIT](context, params) {
      return new Promise((resolve, reject) => {
        let logbooks = [];
        for (let logbook in params.data) {
          logbooks.push(params.data[logbook])
        }

        RouteFilesService.updateLogbooks(params.routeFileId, logbooks)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [ROUTE_FILES_INIT_EDIT_DATA](context, id) {
      return new Promise((resolve, reject) => {
        RouteFilesService.get(id)
          .then((response) => {
            context.commit(ROUTE_FILES_INIT_EDIT_DATA_M, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    [ROUTE_FILES_LOGS_INIT_EDIT_DATA](context, id) {
      return new Promise((resolve, reject) => {
        RouteFilesService.getLogbooksForRouteFileId(id)
          .then((response) => {
            context.commit(ROUTE_FILES_INIT_EDIT_DATA_M, response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [ROUTE_FILES_EDIT_SUMBIT](context, data) {
      return new Promise((resolve, reject) => {
        RouteFilesService.update(data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [FETCH_ROUTE_STATIONS](context, params) {
      const { page, perPage, filters } = params;
      let query = { page, perPage };
      if (filters && filters.statia && filters.statia != '') {
        query.statia = ['like', filters.statia];
      }

      return new Promise((resolve, reject) => {
        RouteFilesService.getPredefinedStations(query)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.notify({
              group: 'errors',
              type: 'error',
              title: error,
            })
            reject(error);
          });
      });
    },
  },
};
